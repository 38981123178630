export default global.errormessage = {
    getmessages: {
        messages: {
            err_valid: "Please enter valid details",
            //login:
            erremail: "Please enter email",
            errvalidemail: "Please enter valid email",
            errpassword: "Please enter password",
            errvalidpassword: "Please enter valid password",
            errnewpassword:"Please enter new password",
            erroldpassword:"Please enter old password",
            //change email
            err_currentemail:"Please enter current email",
            err_newemail:"Please enter new email",
            err_getotp:"Please enter OTP number",
            err_validcurrentemail:"Please enter valid email",
            err_validnewemail:"Please enter valid email ",
            //profile info
            errprofile_industryname:"Please select industryname",
            errprofile_companytypename:"Please select companytypename",
            errprofile_employertypename:"Please select employertypename",
            registeredname:"Please enter registeredname",
            //company info
            erraboutcompany:"Please enter aboutcompany",
            errturnover:"Please enter companyturnover",
            errnoofemployees:"Please enter noofemployees",
            errcompanybenefits:"Please select companybenefits",
            //preference
            errpref_jobfunction:"Please select jobfunction",
            errpref_jobrole:"Please select jobrole",
            errpref_joblocation:"Please select joblocation",
            //contactinfo
            errcontact_state:"Please select state",
            errcontact_district:"Please select district",
            errcontact_city:"Please select city",
            errvarpincode:"Please enter valid pincode",
            errvarcontact_address:"Please enter companyaddress",
            errvarcontact_phoneno_invalid:"Please enter mobilenumber",
            errvarcontact_landline_invalid:"Please enter Phonenumber",
            errvarcontact_Website_invalid:"Please enter website",
            //branch
            errbranch_companyaddress:"Please enter company address",
            errbranch_pincode:"Please enter valid pincode",
            errbranch_city:"Please select city",
            errbranch_selectedstate:"Please select state",
            errbranch_selecteddistrict:"Please select district",
            errbranch_telephone:"Please enter phone number",
            errbranch_mobileno:"Please enter mobile number",
            errbranch_designation:"Please enter designation",
            errbranch_contactperson:"Please enter contactperson name",
            errbranch_name:"Please enter branch name",
            errbranchemail_invalid:"Please enter email",
           //contact_us
            errmessage: "please enter message",
            errsubject: "please select subject",
            timeset: "3000",
            //New job post
            err_invalidBranch: "Please select branch",
            err_invalidEducationcategory: "Please select educationcategory",
            err_invalidQualification: "Please select qualification",
            err_invalidSpecialization: "Please select specialization",
            err_invalidJobFunction: "Please select jobfunction",
            err_invalidJobRole: "Please select jobrole",
            err_invalidSkills: "Please select skill",
            err_invalidExperience: "Please select experience",
            err_invalidJobType: "Please select jobtype",
            err_invalidWorkTiming: "Please enter workingtime",
            err_invalidNoofOpenings: "Please enter no of openings",
            err_invalidFacilities: "Please select facilities",
            err_invalidLastDate: "Please select last date",
            err_invalidDescription: "Please enter desceiption",
            err_invalidContactPersonName: "Please enter name",
            err_invalidEmailId: "Please enter valid emailid",
            err_invalidMobileno: "Please enter valid mobileno",
            err_invalidLanguage: "Please select Language",
            err_invalidGender: "Please select Gender",
            err_invalidMartial: "Please select Martial",
            err_invalidAgeTo: "Please enter Ageto",
            err_invalidAgeFrom: "Please enter AgeFrom",
            err_invalidexpFrom: "Please enter Experience",
            err_invalidexpTo: 'Please enter valid Experience',
            err_invalidSalaryMax : 'Please enter valid Max Salary',
            err_invalidSalaryMin : 'Please enter valid mininum salary',
            //subscription contact
            errtxt_noofposts:"Please enter no. of jobs allowed for the package",
            errtxt_jobvacancies:"Please enter no. of vacancies allowed for the job post",
            errtxt_profile:"Please enter no. of profiles to be viewed by the employer",
            errdescription:"Please enter description",
            err_invaliddesignation:"Please enter designation",
            err_invalidaddress:"Please enter addresss",
            //signup
            errstate:"Please select the state",
            errdistrict:"Please select the district",
            errindustry_type:"Please select the industry / sector",
            errdp_employmenttype:"Please select the employer type",
            erractivity_type:"Please select the activity type",
            errcity:"Please select the City/Town  ",
            errcompany:"Please enter company name",
            errtxt_email:"Please enter email",
            errtxt_mobileno:"Please enter mobile number",
            errcompanyname:"Please enter company name",
            errpassword:"Please enter password",
            errtelephone:"Please enter telephone number",
            errwebsite:"please enter website",
            erraddress:"Please enter addresss",
            errdesignation:"Please enter the designation",
            errgstin:"Please enter GSTIN Number",
            errpan:"Please enter Pan Number",
            err_idproof:"Please choose atleast one idproof",
            errAadharno:"Please enter Aadhar Number" ,
            errfacility:"please select faclity offered",
            errknowntype:"Please select knowntype",
            errdocgstin:"Please upload Document",
            errAadharnodoc:"Please upload Document",
            errpandoc:"Please upload Document",
            errmobilevalid:"please enter valid mobile number",
            errgstinvalid:"Enter valid gstin number",
            errpaninvalid:"Enter valid Pan number",
            errAadharnoinvalid:"Enter valid Aadhar number",
            errcompanytype:"Please select the company type",
            errcontact:"Please enter contact person",
            errothers:"please enter others",
            errotp:"Please enter otp nmuber",
            errvalidotp:"Please enter valid otp number",
            errconfirmpassword:"Please enter confirm password",
            err_confirmpassword:"Confirm password does not match",
            erruserlist:"Please select the user",
            errpasswordlength:"Please enter atleast 6 characters password",
            errtxt_pincode:"Please enter valid pincode"
        }
    }
}
