import React from "react"
import { Row, Col } from "reactstrap"
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Badge,
  Media,
  Dropdown,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  UncontrolledButtonDropdown,
  Card,
  CardBody
} from "reactstrap"
import axios from "axios"
import classnames from "classnames"
import stringsoflanguages from '../../../Stringsoflanguage'
//import * as Icon from "react-feather"
import PerfectScrollbar from "react-perfect-scrollbar"
import { useAuth0 } from "../../../authServices/auth0/auth0Service"
import { history } from "../../../history"
import { ChevronDown } from "react-feather"
import SweetAlert from 'react-bootstrap-sweetalert'
import { IntlContext } from "../../../utility/context/Internationalization"
import { valid } from "chroma-js"
import { generateRefreshToken } from "../../../serviceWorker"

const handleNavigation = (e, path) => {
  e.preventDefault()
  history.push(path)
}
const UserDropdown = props => {
  const { logout, isAuthenticated } = useAuth0()
  return (
    <p></p>
  )
}
let error_log = require('../../../error_log');
class NavbarUser extends React.PureComponent {
  state = {
    navbarSearch: false,
    langDropdown: false,
    suggestions: []
  }
  constructor(props) {
    super(props);
    this.state = {
      telegram_employer: '',
      youtubelink: '',
      fblink: '',
      hover_content_jobs: "",
      hover_content_news: "",
      hover_content_events: "",
      news_invidual_count: 0,
      events_invidual_count: 0,
      jobs_invidual_count: 0,
      activeTab: "1",
      active: "1",
      activeIndex: 0,
      overallcount_notification: 0,
      notify_response_currentdate: "",
      notification_list: [],
      ipurl: global.config.geturl.ipaddress.url,
      usercode: localStorage.getItem('employercode'),
      version: global.commonvariable.getcommon.variable.version,
      varcompany_profileimg: '',
      infocircle_icon: require("../../../assets/img/portrait/small/info_circle.png"),
      employername_login: localStorage.getItem('registeredname'),
      defaultAlert: false,
      confirmAlert: false,
      cancelAlert: false,
      media_content: [],
      media_id: [],
      border: " ",
      list: [],
      index: [],
      notification_list_news: [],
      notification_list_jobs: [],
      defaultcode: '',
      dataSource: JSON.parse(localStorage.getItem('languagedata')),
      // dataSource: [],
    }
  }
  componentDidMount() {
    
    try {
      if (localStorage.getItem('languagecode_short') != '') {
        stringsoflanguages.setLanguage(localStorage.getItem('languagecode_short'))
      }
      this.joinus();
      this.list_notifications();
      this.list_notification_jobs();
      this.list_notifications_news();
      this.listcount();
      this.image_view();
      // [{"languagecode":1,"languagename":"தமிழ்","shorttype":"ta"},{"languagecode":2,"languagename":"English","shorttype":"en"}]
      if (!this.state.dataSource || (this.state.dataSource && this.state.dataSource.length == 0) || this.state.dataSource == "" || this.state.dataSource == null || this.state.dataSource == undefined) {
        try {
          window.fbq('track', 'Language Bind', { fb_ad_function: "Language Bind", fb_ad_type: "NavbarUser", fb_ad_loginuser: global.commonvariable.getcommon.variable.registeredname });
          axios({
            method: 'Post',
            url: this.state.ipurl + '/languagebind',
            crossdomain: true,
            params: {
              ipaddress: localStorage.getItem('ipaddress'),
            }
          })
            .then(response => {
              if (localStorage.getItem('languagecode') == '' || localStorage.getItem('languagecode') == null || localStorage.getItem('languagecode') == undefined) {
                localStorage.setItem('languagecode', '2');
              } else {

              }
              if (localStorage.getItem('languagecode_short') == '' || localStorage.getItem('languagecode_short') == null || localStorage.getItem('languagecode_short') == undefined) {
                localStorage.setItem('languagecode_short', 'en')
                stringsoflanguages.setLanguage('en')
              } else {

              }

              if (response.data.hasOwnProperty('language_json_result')) {
                this.state.statuscode = (response.data.language_json_result.hasOwnProperty('varstatuscode')) ? response.data.language_json_result.varstatuscode : 0;
                this.state.defaultcode = (response.data.language_json_result.hasOwnProperty('defaultlanguagecode')) ? response.data.language_json_result.defaultlanguagecode : 0;
                if (this.state.statuscode == 4) {
                  this.state.dataSource = (response.data.language_json_result.hasOwnProperty('languagelist')) ? response.data.language_json_result.languagelist : [];
                  this.setState({ dataSource: response.data.language_json_result.languagelist })
                  localStorage.setItem('languagedata', JSON.stringify(this.state.dataSource))
                }
              }
            })
            .catch(error => {
              error_log.ERRORLOG(this.state.usercode, this.state.version, "NavbarUser", "language_bind", 0, error.toString());
            });
        } catch (error) {
          error_log.ERRORLOG(this.state.usercode, this.state.version, "NavbarUser", "language_bind", 0, error.toString());
        }
      }
    } catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "NavbarUser", "componentDidMount", 0, error.toString());
    }
  }
  handleNavbarSearch = () => {
    try {
      this.setState({ navbarSearch: !this.state.navbarSearch })
    } catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "NavbarUser", "handleNavbarSearch", 0, error.toString());
    }
  }
  onMouseShow = () => {
    try {
      this.setState({ hover_content_events: "true" })
    } catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "NavbarUser", "onMouseShow", 0, error.toString());
    }
  }
  onMouseRemove = () => {
    try {
      this.setState({ hover_content_events: "" })
    } catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "NavbarUser", "onMouseRemove", 0, error.toString());
    }
  }
  handleAlert = (state, value) => {
    try {
      this.setState({ [state]: value })
    } catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "NavbarUser", "handleAlert", 0, error.toString());
    }
  }
  changeUnmountOnClose = e => {
    try {
      let value = e.target.value
      this.setState({ unmountOnClose: JSON.parse(value) })
    } catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "NavbarUser", "changeUnmountOnClose", 0, error.toString());
    }
  }

  logout() {
    try {
      this.handleAlert("defaultAlert", false);
      this.handleAlert("confirmAlert", false);
      localStorage.setItem('registeredemail', "");
      localStorage.setItem('employer_contactno', '');
      localStorage.setItem('registeredname', "");
      localStorage.setItem('employercode', "");
      localStorage.setItem('statuscode', "");
      localStorage.setItem('result', "");
      localStorage.setItem('ipaddress', "");
      localStorage.setItem('appcode', "");
      localStorage.setItem('remember_me',0);
      localStorage.clear();
      history.push('/');
    } catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "NavbarUser", "logout", 0, error.toString());
    }
  }
  //notification count overall
  listcount() {
    var accessToken =  localStorage.getItem('accessToken');

    try {
      window.fbq('track', 'Notification Notificationcount', { fb_ad_function: "Notification Notificationcount", fb_ad_type: "NavbarUser", fb_ad_loginuser: global.commonvariable.getcommon.variable.registeredname });
      axios({
        method: 'Post',
        url: this.state.ipurl + 'notification/notificationcount/',
        crossdomain: true,
           headers: {
          'Authorization': 'Bearer ' + accessToken,
          'Content-Type': 'application/json'
        },
        params: {
          usercode: Number(this.state.usercode),
          ipaddress: localStorage.getItem('ipaddress'),
          apptypecode: 2,
        }
      })
        .then(async response => {
          if(response.data.status == 401)
          {
           await generateRefreshToken().then((result)=>{
              if(result == 1)
              {
                this.listcount();
              }
              else{
                // alert('Unauthorized')
              }
            })
       
          }
          else{
            if (response.data.notification_json_result.varstatuscode === 4) {
              let overallcount_notification = response.data.notification_json_result.overall_count
              this.setState({ overallcount_notification: overallcount_notification })
            }
            else {
              this.setState({ overallcount_notification: 0 })
            }
          }
        })
        .catch(error => {
          error_log.ERRORLOG(this.state.usercode, this.state.version, "NavbarUser", "listcount", 0, error.toString());
        });
    } catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "NavbarUser", "listcount", 0, error.toString());
    }
  }

  //notification list
  notificationlist() {
    var accessToken =  localStorage.getItem('accessToken');

    try {
      window.fbq('track', 'Notification Notificationlist', { fb_ad_function: "Notification Notificationlist", fb_ad_type: "NavbarUser", fb_ad_loginuser: global.commonvariable.getcommon.variable.registeredname });
      axios({
        method: 'Post',
        url: this.state.ipurl + 'notification/notificationlist/',
        crossdomain: true,
        headers: {
          'Authorization': 'Bearer ' + accessToken,
          'Content-Type': 'application/json'
        },
        params: {
          usercode: Number(this.state.usercode),
          ipaddress: localStorage.getItem('ipaddress'),
          apptypecode: 2,
          typecode: 1,
          languagecode: Number(localStorage.getItem('languagecode')),
        }
        , data: {
          notificationtypecode: [2] // Job related notifications
        }
      })
        .then(async response => {
          if(response.data.status == 401)
          {
           await generateRefreshToken().then((result)=>{
              if(result == 1)
              {
                this.notificationlist();
              }
              else{
                // alert('Unauthorized')
              }
            })
       
          }
          else{

          if (response.data.notification_json_result.varstatuscode === 4) {
            //current time
            let notification_currenttime = response.data.notification_json_result.currenttime
            if (notification_currenttime) {
              this.setState({ notify_response_currentdate: notification_currenttime })
            }
            let notificationlist = response.data.notification_json_result.nodification_list
            if (notificationlist) {
              JSON.stringify(notificationlist)
              this.setState({ notification_list: notificationlist })
            }
          }
          else {
            this.setState({ notificationlist: [] })
          }
        }
        })
        .catch(error => {
          error_log.ERRORLOG(this.state.usercode, this.state.version, "NavbarUser", "notificationlist", 0, error.toString());
        });
    } catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "NavbarUser", "notificationlist", 0, error.toString());
    }
  }


  notification_message_clic(notify_code) {
    try {
      if (notify_code === 1) {
        history.push("/News_Events/News");
      }
      else if (notify_code === 2) {
        history.push("/News_Events/Events");
      }
      else if (notify_code === 3) {
        history.push("/myjobs/MyJobPosts");
      }
    } catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "NavbarUser", "notification_message_clic", 0, error.toString());
    }
  }

  notification_view_list() {
    try {
      if (this.state.notification_list === "" || this.state.notification_list.length === 0) {
        return (
          <div className="norecord-info">
            <small>  {stringsoflanguages.No_Notifications_yet} </small>
          </div>
        )

      }
      if (this.state.notification_list && this.state.notification_list.length > 0) {
        return this.state.notification_list.map((el, index) => {
          const { notificationmessage, notificationtime, notificationcode, notificationtypename, notificationtypecode, notificationtypeid, usercode, viewedstatuscode } = el;
          var common_time = 0;
          var msPerMinute = 60 * 1000;
          var msPerHour = msPerMinute * 60;
          var msPerDay = msPerHour * 24;
          var msPerMonth = msPerDay * 30;
          var msPerYear = msPerDay * 365;
          var elapsed = this.state.notify_response_currentdate - notificationtime;
          if (elapsed == 0) {
            common_time = 'Just now';
          }
          if (elapsed < msPerMinute) {
            common_time = Math.round(elapsed / 1000) + ' seconds ago';
          }
          else if (elapsed < msPerHour) {
            common_time = Math.round(elapsed / msPerMinute) + ' minutes ago';
          }
          else if (elapsed < msPerDay) {
            common_time = Math.round(elapsed / msPerHour) + ' hours ago';
          }
          else if (elapsed < msPerMonth) {
            common_time = Math.round(elapsed / msPerDay) + ' days ago';
          }
          else if (elapsed < msPerYear) {
            common_time = Math.round(elapsed / msPerMonth) + ' months ago';
          }
          else {
            common_time = Math.round(elapsed / msPerYear) + ' years ago';
          }
          return (
            <div key={index}>
              <div className="notify-border" onMouseEnter={this.onMouseShow}
                onMouseLeave={this.onMouseRemove}  >
                <Row className="notify-row">
                  <Col md="9" onClick={() => this.notification_message_clic(notificationtypecode)}>
                    <div className="notify-margin">
                      <div heading tag="h6" style={{ lineHeight: "1.1" }}>
                        <span className="cursor-pointer" onClick={() => this.viewedstatus_events(notificationtypecode, notificationcode)}>{notificationmessage}</span>
                      </div>
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="btton">
                      {this.state.hover_content_events ?
                        <button className="image_btn_style1" onClick={() => this.dismiss_single(notificationtypecode, notificationcode)} >
                          <i className="fa fa-times closeicon" aria-hidden="true"></i>
                        </button> : ""}
                    </div>
                    <div className="notify-time" heading tag="h6" style={{ marginLeft: "-13px" }}>
                      <small>{common_time} </small>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          )
        })
      }
    } catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "NavbarUser", "notification_view_list", 0, error.toString());
    }
  }

  readallnotify() {
    try {
      history.push("/readall_notifications")
    } catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "NavbarUser", "readallnotify", 0, error.toString());
    }
  }

  toggleTab = tab => {
    try {
      if (this.state.activeTab !== tab) {
        this.setState({ activeTab: this.state.activeTab = tab })
      }
    } catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "NavbarUser", "toggleTab", 0, error.toString());
    }
  }

  toggle = tab => {
    try {
      if (tab == 2) {
        this.setState({ varhideid: true });
        this.setState({ varshowid: false })
      }
      else {
        this.setState({ varhideid: false });
        this.setState({ varshowid: true });
      }
      if (this.state.active !== tab) {
        this.setState({ active: tab })
      }

      // if (tab == 2) {
      //   this.setState({ varhideid: this.state.varhideid = true });
      //   this.setState({ varshowid: this.state.varshowid = false })
      // }
      // else {
      //   this.setState({ varhideid: this.state.varhideid = false });
      //   this.setState({ varshowid: this.state.varshowid = true });
      // }
      // if (this.state.active !== tab) {
      //   this.setState({ active: this.state.active = tab })
      // }
    } catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "NavbarUser", "toggle", 0, error.toString());
    }
  }

  applied_count_zero = tab => {
    try {
      if (tab == 2) {
        this.setState({ varhideid: this.state.varhideid = false });
        this.setState({ varshowid: this.state.varshowid = true });
        this.setState({ rowData: this.state.varapplieddate })
        this.setState({ rowData: this.state.varinvitedate })
        this.setState({ rowData: this.state.varshortdate })
      }
      if (this.state.active !== tab) {
        this.setState({ active: tab })
        this.setState({ rowData: this.state.varapplieddate })
        this.setState({ rowData: this.state.varinvitedate })
        this.setState({ rowData: this.state.varshortdate })
      }
    } catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "NavbarUser", "applied_count_zero", 0, error.toString());
    }
  }



  joinus() {
   var accessToken =  localStorage.getItem('accessToken');
    try {
      window.fbq('track', 'Joinus Details', { fb_ad_function: "Joinus Details", fb_ad_type: "NavbarUser", fb_ad_loginuser: global.commonvariable.getcommon.variable.registeredname });
      axios({
        method: 'Post',
        url: this.state.ipurl + 'joinusdetails/',
        crossdomain: true,
        headers: {
          'Authorization': 'Bearer ' + accessToken,
          'Content-Type': 'application/json'
        },
        params: {
          employercode: Number(this.state.usercode),
          ipaddress: localStorage.getItem('ipaddress'),
        }
      })
        .then(async response => {
        
          if(response.data.status == 401)
          {
           await generateRefreshToken().then((result)=>{
              if(result == 1)
              {
                this.joinus();
              }
              else{
                // alert('Unauthorized')
              }
            })
       
          }
          else{
            let list;
            if (response.data.hasOwnProperty('joinus_json_result')) {
              if (response.data.joinus_json_result.hasOwnProperty('joinuslist')) {
                list = response.data.joinus_json_result.joinuslist
                if (list.length > 0) {
                  for (let i = 0; i < list.length; i++) {
                    if (list[i].joinuscode == 1)//telegram_employer
                      this.setState({ telegram_employer: this.state.telegram_employer = list[i].link })
                    if (list[i].joinuscode == 3)//facebook
                      this.setState({ fblink: this.state.fblink = list[i].link })
                    if (list[i].joinuscode == 4)//youtube
                      this.setState({ youtubelink: this.state.youtubelink = list[i].link })
                  }
                }
              }
            }
          }
         
         
   
        })
        .catch(error => {
          error_log.ERRORLOG(this.state.usercode, this.state.version, "NavbarUser", "joinus", 0, error.toString());
        });
    } catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "NavbarUser", "joinus", 0, error.toString());
    }
  }
  // generateRefreshToken()
  // {

  //   try {
  //     var refreshToken =  localStorage.getItem('refreshToken');
  //     window.fbq('track', 'Joinus Details', { fb_ad_function: "Joinus Details", fb_ad_type: "NavbarUser", fb_ad_loginuser: global.commonvariable.getcommon.variable.registeredname });
  //     axios({
  //       method: 'Post',
  //       url: this.state.ipurl + 'employer/refreshToken/',
  //       crossdomain: true,
  
  //       params: {
  //         token: refreshToken,
         
  //       }
  //     })
  //       .then(response => {
  //         if(response.status == 498)
  //         {
  //          alert('unautorized');
  //          return false;
  //         }
  //         else{

          
         
  //         if (response.data.hasOwnProperty('tokenReult')) {
  //           if (response.data.tokenReult.hasOwnProperty('accessToken')) {
  //             localStorage.setItem('accessToken', response.data.tokenReult.accessToken)
  //             localStorage.setItem('refreshToken', response.data.tokenReult.refreshToken)
  //             return true
  //           }
  //           this.joinus();
  //         }
  //       }
  //       })
  //       .catch(error => {
  //         error_log.ERRORLOG(this.state.usercode, this.state.version, "NavbarUser", "joinus", 0, error.toString());
  //       });
  //   } catch (error) {
  //     error_log.ERRORLOG(this.state.usercode, this.state.version, "NavbarUser", "joinus", 0, error.toString());
  //   }
  // }
  //notification list
  list_notifications() {
    var accessToken =  localStorage.getItem('accessToken');

    try {
      window.fbq('track', 'Notification Notificationlist', { fb_ad_function: "Notification Notificationlist", fb_ad_type: "NavbarUser", fb_ad_loginuser: global.commonvariable.getcommon.variable.registeredname });
      axios({
        method: 'Post',
        url: this.state.ipurl + 'notification/notificationlist/',
        crossdomain: true,
        headers: {
          'Authorization': 'Bearer ' + accessToken,
          'Content-Type': 'application/json'
        },
        params: {
          usercode: Number(this.state.usercode),
          ipaddress: localStorage.getItem('ipaddress'),
          apptypecode: 2,
          typecode: 1,
          languagecode: Number(localStorage.getItem('languagecode')),
        }, data: {
          notificationtypecode: [2] // Job related notifications
        }
      })
        .then(async response => {
          if(response.data.status == 401)
          {
           await generateRefreshToken().then((result)=>{
              if(result == 1)
              {
                this.list_notifications();
              }
              else{
                // alert('Unauthorized')
              }
            })
       
          }
          else{


          if (response.data.notification_json_result.varstatuscode === 4) {
            //current time
            let notification_currenttime = response.data.notification_json_result.currenttime
            if (notification_currenttime) {
              this.setState({ notify_response_currentdate: notification_currenttime })
            }
            let notificationlist = response.data.notification_json_result.nodification_list
            if (notificationlist) {
              JSON.stringify(notificationlist)
              this.setState({ notification_list: notificationlist })
            }
          }
          else {
            this.setState({ notificationlist: [] })
          }
        }
        })
        .catch(error => {
          error_log.ERRORLOG(this.state.usercode, this.state.version, "NavbarUser", "list_notifications", 0, error.toString());
        });
    } catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "NavbarUser", "list_notifications", 0, error.toString());
    }
  }
  dismiss_single(notificationtypecode, notificationcode) {
    var accessToken =  localStorage.getItem('accessToken');

    try {
      window.fbq('track', 'Notification Dismiss Notification', { fb_ad_function: "Notification Dismiss Notification", fb_ad_type: "LoginJWT", fb_ad_loginuser: global.commonvariable.getcommon.variable.registeredname });
      axios({
        method: 'Post',
        url: this.state.ipurl + 'notification/dismissnotification/',
        crossdomain: true,
        headers: {
          'Authorization': 'Bearer ' + accessToken,
          'Content-Type': 'application/json'
        },
        params: {
          usercode: Number(this.state.usercode),
          ipaddress: localStorage.getItem('ipaddress'),
          typecode: 1,
          apptypecode: 2,
          notificationcode: notificationcode
        }, data: { notificationtypecode: [notificationtypecode] }
      })
        .then(async response => {
          if(response.data.status == 401)
          {
           await generateRefreshToken().then((result)=>{
              if(result == 1)
              {
                this.dismiss_single();
              }
              else{
                // alert('Unauthorized')
              }
            })
       
          }
          else{


          if (response.data.notification_json_result.varstatuscode === 2) {
            this.list_notifications();
          }
      
        }
        })
        .catch(error => {
          error_log.ERRORLOG(this.state.usercode, this.state.version, "NavbarUser", "dismiss_single", 0, error.toString());
        });
    } catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "NavbarUser", "dismiss_single", 0, error.toString());
    }
  }

  viewedstatus_events(notificationtypecode, notificationcode) {
    try {
      var accessToken =  localStorage.getItem('accessToken');

      window.fbq('track', 'Notification Viewed Status', { fb_ad_function: "Notification Viewed Status", fb_ad_type: "NavbarUser", fb_ad_loginuser: global.commonvariable.getcommon.variable.registeredname });
      axios({
        method: 'Post',
        url: this.state.ipurl + 'notification/viewedstatus/',
        crossdomain: true,
        headers: {
          'Authorization': 'Bearer ' + accessToken,
          'Content-Type': 'application/json'
        },
        params: {
          usercode: Number(this.state.usercode),
          ipaddress: localStorage.getItem('ipaddress'),
          apptypecode: 2,
          notificationcode: notificationcode
        }, date: { notificationtypecode: [notificationtypecode] }
      })
        .then(async response => {

          if(response.data.status == 401)
          {
           await generateRefreshToken().then((result)=>{
              if(result == 1)
              {
                this.viewedstatus_events();
              }
              else{
                // alert('Unauthorized')
              }
            })
       
          }
          else{


          if (response.data.notification_json_result.varstatuscode === 2) {
            this.list_notification_jobs();
          }
        }
        })
        .catch(error => {
          error_log.ERRORLOG(this.state.usercode, this.state.version, "NavbarUser", "viewedstatus_events", 0, error.toString());
        });
    } catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "NavbarUser", "viewedstatus_events", 0, error.toString());
    }
  }
  //news

  //notification list
  list_notifications_news() {
    var accessToken =  localStorage.getItem('accessToken');
    try {
      window.fbq('track', 'Notification Notification List', { fb_ad_function: "Notification Notification List", fb_ad_type: "NavbarUser", fb_ad_loginuser: global.commonvariable.getcommon.variable.registeredname });
      axios({
        method: 'Post',
        url: this.state.ipurl + 'notification/notificationlist/',
        crossdomain: true,
        headers: {
          'Authorization': 'Bearer ' + accessToken,
          'Content-Type': 'application/json'
        },
        params: {
          usercode: Number(this.state.usercode),
          ipaddress: localStorage.getItem('ipaddress'),
          apptypecode: 2,
          typecode: 1,
          languagecode: Number(localStorage.getItem('languagecode')),
        }, data: {
          notificationtypecode: [1] // Job related notifications
        }
      })
        .then(async response => {

          if(response.data.status == 401)
          {
           await generateRefreshToken().then((result)=>{
              if(result == 1)
              {
                this.list_notifications_news();
              }
              else{
                // alert('Unauthorized')
              }
            })
       
          }
          else{

          if (response.data.notification_json_result.varstatuscode === 4) {
            //current time
            let notification_currenttime = response.data.notification_json_result.currenttime
            if (notification_currenttime) {
              this.setState({ notify_response_currentdate: notification_currenttime })
            }
            let notificationlist = response.data.notification_json_result.nodification_list
            if (notificationlist) {
              JSON.stringify(notificationlist)
              this.setState({ notification_list_news: notificationlist })
            }
          }
          else {
            this.setState({ notificationlist: [] })
          }
        }
        })
        .catch(error => {
          error_log.ERRORLOG(this.state.usercode, this.state.version, "NavbarUser", "list_notifications_news", 0, error.toString());
        });
    } catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "NavbarUser", "list_notifications_news", 0, error.toString());
    }
  }


  notification_view_list_news() {
    try {
      if (this.state.notification_list_news === "" || this.state.notification_list_news.length === 0) {
        return (
          <div className="norecord-info">
            <small>  {stringsoflanguages.No_Notifications_yet} </small>
          </div>
        )

      }
      if (this.state.notification_list_news && this.state.notification_list_news.length > 0) {
        return this.state.notification_list_news.map((el, index) => {
          const { notificationmessage, notificationtime, notificationcode, notificationtypecode, notificationtypename, notificationtypeid, usercode, viewedstatuscode } = el;
          var common_time = 0;
          var msPerMinute = 60 * 1000;
          var msPerHour = msPerMinute * 60;
          var msPerDay = msPerHour * 24;
          var msPerMonth = msPerDay * 30;
          var msPerYear = msPerDay * 365;
          var elapsed = this.state.notify_response_currentdate - notificationtime;
          if (elapsed == 0) {
            common_time = 'Just now';
          }
          if (elapsed < msPerMinute) {
            common_time = Math.round(elapsed / 1000) + ' seconds ago';
          }
          else if (elapsed < msPerHour) {
            common_time = Math.round(elapsed / msPerMinute) + ' minutes ago';
          }
          else if (elapsed < msPerDay) {
            common_time = Math.round(elapsed / msPerHour) + ' hours ago';
          }
          else if (elapsed < msPerMonth) {
            common_time = Math.round(elapsed / msPerDay) + ' days ago';
          }
          else if (elapsed < msPerYear) {
            common_time = Math.round(elapsed / msPerMonth) + ' months ago';
          }
          else {
            common_time = Math.round(elapsed / msPerYear) + ' years ago';
          }
          return (
            <div key={index}>
              <div className="notify-border" onMouseEnter={this.onMouseShow_news}
                onMouseLeave={this.onMouseRemove_news} >
                <Row className="notify-row">
                  <Col md="9" onClick={() => this.notification_message_clic_news()} >
                    <div className="notify-margin">
                      <div heading tag="h6" style={{ lineHeight: "91%" }}>
                        <span className="cursor-pointer" onClick={() => this.viewedstatus_news(notificationtypecode, notificationcode)}>{notificationmessage}</span>
                      </div>
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="btton">
                      {this.state.hover_content_news ?
                        <button className="image_btn_style1" onClick={() => this.dismiss_single_news(notificationtypecode, notificationcode)} >
                          <i className="fa fa-times closeicon" aria-hidden="true"></i>
                        </button> : ""}
                    </div>
                    <div className="notify-time" heading tag="h6" style={{ marginLeft: "-13px" }}>
                      <small>{common_time} </small>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          )
        })
      }
    } catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "NavbarUser", "notification_view_list_news", 0, error.toString());
    }
  }

  notification_message_clic_news() {
    try {
      history.push("/News_Events/News");
    } catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "NavbarUser", "notification_message_clic_news", 0, error.toString());
    }
  }


  onMouseShow_news = () => {
    try {
      this.setState({ hover_content_news: "true" })
    } catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "NavbarUser", "onMouseShow_news", 0, error.toString());
    }
  }
  onMouseRemove_news = () => {
    try {
      this.setState({ hover_content_news: "" })
    } catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "NavbarUser", "onMouseRemove_news", 0, error.toString());
    }
  }


  dismiss_single_news(notificationtypecode, notificationcode) {
    var accessToken =  localStorage.getItem('accessToken');

    try {
      window.fbq('track', 'Notification Dismiss Notification', { fb_ad_function: "Notification Dismiss Notification", fb_ad_type: "NavbarUser", fb_ad_loginuser: global.commonvariable.getcommon.variable.registeredname });
      axios({
        method: 'Post',
        url: this.state.ipurl + 'notification/dismissnotification/',
        crossdomain: true,
        headers: {
          'Authorization': 'Bearer ' + accessToken,
          'Content-Type': 'application/json'
        },
        params: {
          usercode: Number(this.state.usercode),
          ipaddress: localStorage.getItem('ipaddress'),
          typecode: 1,
          apptypecode: 2,
          notificationcode: notificationcode
        }, data: { notificationtypecode: [notificationtypecode] }
      })
        .then(async response => {
          if(response.data.status == 401)
          {
           await generateRefreshToken().then((result)=>{
              if(result == 1)
              {
                this.dismiss_single_news();
              }
              else{
                // alert('Unauthorized')
              }
            })
       
          }
          else{
          if (response.data.notification_json_result.varstatuscode === 2) {
            this.list_notifications_news();
          }
        
        }
        })
        .catch(error => {
          error_log.ERRORLOG(this.state.usercode, this.state.version, "NavbarUser", "dismiss_single_news", 0, error.toString());
        });
    } catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "NavbarUser", "dismiss_single_news", 0, error.toString());
    }
  }

  viewedstatus_news(notificationtypecode, notificationcode) {
    var accessToken =  localStorage.getItem('accessToken');

    try {
      window.fbq('track', 'Notification Viewed Status', { fb_ad_function: "Notification Viewed Status", fb_ad_type: "NavbarUser", fb_ad_loginuser: global.commonvariable.getcommon.variable.registeredname });
      axios({
        method: 'Post',
        url: this.state.ipurl + 'notification/viewedstatus/',
        crossdomain: true,
        headers: {
          'Authorization': 'Bearer ' + accessToken,
          'Content-Type': 'application/json'
        },
        params: {
          usercode: Number(this.state.usercode),
          ipaddress: localStorage.getItem('ipaddress'),
          apptypecode: 2,
          notificationcode: notificationcode
        }, date: { notificationtypecode: [notificationtypecode] }
      })
        .then(async response => {

          if(response.data.status == 401)
          {
           await generateRefreshToken().then((result)=>{
              if(result == 1)
              {
                this.viewedstatus_news();
              }
              else{
                // alert('Unauthorized')
              }
            })
       
          }
          else{

          if (response.data.notification_json_result.varstatuscode === 2) {
            this.list_notification_jobs();
          }
        }
        })
        .catch(error => {
          error_log.ERRORLOG(this.state.usercode, this.state.version, "NavbarUser", "viewedstatus_news", 0, error.toString());
        });
    } catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "NavbarUser", "viewedstatus_news", 0, error.toString());
    }
  }

  //jobs
  list_notification_jobs() {
    var accessToken =  localStorage.getItem('accessToken');

    try {
      window.fbq('track', 'Notification Notification List', { fb_ad_function: "Notification Notification List", fb_ad_type: "NavbarUser", fb_ad_loginuser: global.commonvariable.getcommon.variable.registeredname });
      axios({
        method: 'Post',
        url: this.state.ipurl + 'notification/notificationlist/',
        crossdomain: true,
        headers: {
          'Authorization': 'Bearer ' + accessToken,
          'Content-Type': 'application/json'
        },
        params: {
          usercode: Number(this.state.usercode),
          ipaddress: localStorage.getItem('ipaddress'),
          apptypecode: 2,
          typecode: 1,
          languagecode: Number(localStorage.getItem('languagecode')),
        }, data: {
          notificationtypecode: [3] // Job related notifications
        }
      })
        .then(async response => {

          if(response.data.status == 401)
          {
           await generateRefreshToken().then((result)=>{
              if(result == 1)
              {
                this.list_notification_jobs();
              }
              else{
                // alert('Unauthorized')
              }
            })
       
          }
          else{

          if (response.data.notification_json_result.varstatuscode === 4) {
            //current time
            let notification_currenttime = response.data.notification_json_result.currenttime
            if (notification_currenttime) {
              this.setState({ notify_response_currentdate: notification_currenttime })
            }
            let notificationlist = response.data.notification_json_result.nodification_list
            if (notificationlist) {
              JSON.stringify(notificationlist)
              this.setState({ notification_list_jobs: notificationlist })
            }
          }
          else {
            this.setState({ notificationlist: this.state.notificationlist = [] })
          }
        }
        })
        .catch(error => {
          error_log.ERRORLOG(this.state.usercode, this.state.version, "NavbarUser", "list_notification_jobs", 0, error.toString());
        });
    } catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "NavbarUser", "list_notification_jobs", 0, error.toString());
    }
  }


  notification_view_list_jobs() {
    try {
      if (this.state.notification_list_jobs === "" || this.state.notification_list_jobs.length === 0) {
        return (
          <div className="norecord-info">
            <small>{stringsoflanguages.No_Notifications_yet}</small>
          </div>
        )
      }
      if (this.state.notification_list_jobs && this.state.notification_list_jobs.length > 0) {
        return this.state.notification_list_jobs.map((el, index) => {
          const { notificationsubtypecode, notificationmessage, notificationtime, notificationcode, notificationtypecode, notificationtypename, notificationtypeid, usercode, viewedstatuscode } = el;
          var common_time = 0;
          var msPerMinute = 60 * 1000;
          var msPerHour = msPerMinute * 60;
          var msPerDay = msPerHour * 24;
          var msPerMonth = msPerDay * 30;
          var msPerYear = msPerDay * 365;
          var elapsed = this.state.notify_response_currentdate - notificationtime;
          if (elapsed == 0) {
            common_time = 'Just now';
          }
          if (elapsed < msPerMinute) {
            common_time = Math.round(elapsed / 1000) + ' seconds ago';
          }
          else if (elapsed < msPerHour) {
            common_time = Math.round(elapsed / msPerMinute) + ' minutes ago';
          }
          else if (elapsed < msPerDay) {
            common_time = Math.round(elapsed / msPerHour) + ' hours ago';
          }
          else if (elapsed < msPerMonth) {
            common_time = Math.round(elapsed / msPerDay) + ' days ago';
          }
          else if (elapsed < msPerYear) {
            common_time = Math.round(elapsed / msPerMonth) + ' months ago';
          }
          else {
            common_time = Math.round(elapsed / msPerYear) + ' years ago';
          }
          return (
            <div key={index}>
              <div className="notify-border" onMouseEnter={this.onMouseShow_jobs}
                onMouseLeave={this.onMouseRemove_jobs}
              >
                <Row className="notify-row">
                  <Col md="9" onClick={() => this.notification_message_clic_jobs(notificationsubtypecode,notificationtypeid)}>
                    <div className="notify-margin">
                      <div heading tag="h6" style={{ lineHeight: "1.4" }}>
                        <span className="cursor-pointer" onClick={() => this.viewedstatus_jobs(notificationtypecode, notificationcode)}>{notificationmessage}</span>
                      </div>
                    </div>
                  </Col>
                  <Col
                    md="3">
                    <div className="btton">
                      {this.state.hover_content_jobs ?
                        <button className="image_btn_style1" onClick={() => this.dismiss_single_jobs(notificationtypecode, notificationcode)} >
                          <i className="fa fa-times closeicon" aria-hidden="true"></i>
                        </button> : ""}
                    </div>
                    <div className="notify-time" heading tag="h6" style={{ marginLeft: "-13px" }}>
                      <small>{common_time} </small>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>

          )
        })
      }
    } catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "NavbarUser", "notification_view_list_jobs", 0, error.toString());
    }
  }

  notification_message_clic_jobs(notificationsubtypecode,notificationtypeid) {
    try {
      if (notificationsubtypecode && notificationsubtypecode === 1) {
        history.push("/subscription/Subscribe");
      } else {
        localStorage.setItem('employeecode', notificationtypeid)
        localStorage.setItem('dashprofile', 'navbaruser');
        window.open("/myjobs/Employee_Profile_View", "_blank")
      }
    } catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "NavbarUser", "notification_message_clic_jobs", 0, error.toString());
    }
  }
  onMouseShow_jobs = () => {
    try {
      this.setState({ hover_content_jobs: "true" })
    } catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "NavbarUser", "onMouseShow_jobs", 0, error.toString());
    }
  }
  onMouseRemove_jobs = () => {
    try {
      this.setState({ hover_content_jobs: "" })
    } catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "NavbarUser", "onMouseRemove_jobs", 0, error.toString());
    }
  }


  dismiss_single_jobs(notificationtypecode, notificationcode) {
    var accessToken =  localStorage.getItem('accessToken');

    try {
      window.fbq('track', 'Notification Dismiss Notification', { fb_ad_function: "Notification Dismiss Notification", fb_ad_type: "NavbarUser", fb_ad_loginuser: global.commonvariable.getcommon.variable.registeredname });
      axios({
        method: 'Post',
        url: this.state.ipurl + 'notification/dismissnotification/',
        crossdomain: true,
        headers: {
          'Authorization': 'Bearer ' + accessToken,
          'Content-Type': 'application/json'
        },
        params: {
          usercode: Number(this.state.usercode),
          ipaddress: localStorage.getItem('ipaddress'),
          typecode: 1,
          apptypecode: 2,
          notificationcode: notificationcode
        }, data: { notificationtypecode: [notificationtypecode] }
      })
        .then(async response => {
          if(response.data.status == 401)
          {
           await generateRefreshToken().then((result)=>{
              if(result == 1)
              {
                this.dismiss_single_jobs();
              }
              else{
                // alert('Unauthorized')
              }
            })
       
          }
          else{

          if (response.data.notification_json_result.varstatuscode === 2) {
            this.list_notification_jobs();
          }
        
        }
        }).catch(error => {
          error_log.ERRORLOG(this.state.usercode, this.state.version, "NavbarUser", "dismiss_single_jobs", 0, error.toString());
        });
    } catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "NavbarUser", "dismiss_single_jobs", 0, error.toString());
    }
  }
  viewedstatus_jobs(notificationtypecode, notificationcode) {
    var accessToken =  localStorage.getItem('accessToken');

    try {
      window.fbq('track', 'Notification Viewedstatus', { fb_ad_function: "Notification Viewedstatus", fb_ad_type: "NavbarUser", fb_ad_loginuser: global.commonvariable.getcommon.variable.registeredname });
      axios({
        method: 'Post',
        url: this.state.ipurl + 'notification/viewedstatus/',
        crossdomain: true,
        headers: {
          'Authorization': 'Bearer ' + accessToken,
          'Content-Type': 'application/json'
        },
        params: {
          usercode: Number(this.state.usercode),
          ipaddress: localStorage.getItem('ipaddress'),
          apptypecode: 2,
          notificationcode: notificationcode
        }, date: { notificationtypecode: [notificationtypecode] }
      })
        .then(async response => {
          if(response.data.status == 401)
          {
           await generateRefreshToken().then((result)=>{
              if(result == 1)
              {
                this.viewedstatus_jobs();
              }
              else{
                // alert('Unauthorized')
              }
            })
       
          }
          else{
          if (response.data.notification_json_result.varstatuscode === 2) {
            this.list_notification_jobs();
          }
        }
        }).catch(error => {
          error_log.ERRORLOG(this.state.usercode, this.state.version, "NavbarUser", "viewedstatus_jobs", 0, error.toString());
        });
    } catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "NavbarUser", "viewedstatus_jobs", 0, error.toString());
    }

  }

  //dismiss all
  dismiss_all() {
    var accessToken =  localStorage.getItem('accessToken');

    try {
      window.fbq('track', 'Notification Dismiss Notification', { fb_ad_function: "Notification Dismiss Notification", fb_ad_type: "NavbarUser", fb_ad_loginuser: global.commonvariable.getcommon.variable.registeredname });
      axios({
        method: 'Post',
        url: this.state.ipurl + 'notification/dismissnotification/',
        crossdomain: true,
        headers: {
          'Authorization': 'Bearer ' + accessToken,
          'Content-Type': 'application/json'
        },
        params: {
          usercode: Number(this.state.usercode),
          ipaddress: localStorage.getItem('ipaddress'),
          typecode: 2,
          apptypecode: 2,
        }
      })
        .then(async response => {
          if(response.data.status == 401)
          {
           await generateRefreshToken().then((result)=>{
              if(result == 1)
              {
                this.dismiss_all();
              }
              else{
                // alert('Unauthorized')
              }
            })
       
          }
          else{

          if (response.data.notification_json_result.varstatuscode === 2) {
            this.setState({
              news_invidual_count: 0,
              events_invidual_count: 0,
              jobs_invidual_count: 0
            })
            //  this.list_notification_jobs();
            //  this.list_notifications_news();
            //  this.list_notifications();
          }
        
        }
        })
        .catch(error => {
          error_log.ERRORLOG(this.state.usercode, this.state.version, "NavbarUser", "dismiss_all", 0, error.toString());
        });
    } catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "NavbarUser", "dismiss_all", 0, error.toString());
    }
  }
  //myaccount_view list
  image_view() {
    var accessToken =  localStorage.getItem('accessToken');

    try {
      window.fbq('track', 'Employer Get Profileurl', { fb_ad_function: "Employer Get Profileurl", fb_ad_type: "NavbarUser", fb_ad_loginuser: global.commonvariable.getcommon.variable.registeredname });
      axios({
        method: 'Post',
        url: this.state.ipurl + 'employer/getprofileurl/',
        crossdomain: true,
        headers: {
          'Authorization': 'Bearer ' + accessToken,
          'Content-Type': 'application/json'
        },
        params: {
          deviceip: localStorage.getItem("ipaddress"),
          employercode: localStorage.getItem("employercode"),
          languagecode: Number(localStorage.getItem('languagecode')),
        }
      })
        .then(async response => {
          if(response.data.status == 401)
          {
           await generateRefreshToken().then((result)=>{
              if(result == 1)
              {
                this.image_view();
              }
              else{
                // alert('Unauthorized')
              }
            })
       
          }
          else{

          if (response.data.employer_json_result.varstatuscode === 4) {
            if (response.data.employer_json_result.hasOwnProperty('imageurl')) {
              if (response.data.employer_json_result.imageurl) {
                this.setState({ varcompany_profileimg: response.data.employer_json_result.imageurl });
              }
            }
          }
        }
        })
        .catch(error => {
          error_log.ERRORLOG(this.state.usercode, this.state.version, "NavbarUser", "image_view", 0, error.toString());
        });
    } catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "NavbarUser", "image_view", 0, error.toString());
    }
  }
  //notification bell
  notification_bell() {
    var accessToken =  localStorage.getItem('accessToken');

    try {
      window.fbq('track', 'Notification Notification Update Status', { fb_ad_function: "Notification Notification Update Status", fb_ad_type: "NavbarUser", fb_ad_loginuser: global.commonvariable.getcommon.variable.registeredname });
      axios({
        method: 'Post',
        url: this.state.ipurl + 'notification/notificationupdatestatus/',
        crossdomain: true,
        headers: {
          'Authorization': 'Bearer ' + accessToken,
          'Content-Type': 'application/json'
        },
        params: {
          usercode: Number(this.state.usercode),
          ipaddress: localStorage.getItem('ipaddress'),
          typecode: 1,
          apptypecode: 2,
        }
      })
        .then(async response => {

          if(response.data.status == 401)
          {
           await generateRefreshToken().then((result)=>{
              if(result == 1)
              {
                this.notification_bell();
              }
              else{
                // alert('Unauthorized')
              }
            })
       
          }
          else{

          if (response.data.notification_json_result.varstatuscode === 2) {
            this.setState({ overallcount_notification: "" })
            var notification_invidual_count = response.data.notification_json_result.count_list
            if (notification_invidual_count && notification_invidual_count.length > 0) {
              //news
              var newscount = notification_invidual_count.find(t => t.notificationtypecode === 1)
              if (newscount && newscount.totalcount) {
                this.setState({ news_invidual_count: newscount.totalcount })
                this.list_notifications_news();
              }
              //events
              var eventcount = notification_invidual_count.find(t => t.notificationtypecode === 2)
              if (eventcount && eventcount.totalcount) {
                this.setState({ events_invidual_count: eventcount.totalcount })
                this.list_notifications();
              }
              //jobs

              var jobcount = notification_invidual_count.find(t => t.notificationtypecode === 3)
              if (jobcount && jobcount.totalcount) {
                this.setState({ jobs_invidual_count: jobcount.totalcount })
                // this.list_notifications_jobs();
              }
            }

          }
        }
        })
        .catch(error => {
          error_log.ERRORLOG(this.state.usercode, this.state.version, "NavbarUser", "notification_bell", 0, error.toString());
        });
    } catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "NavbarUser", "notification_bell", 0, error.toString());
    }
  }
  render() {
    return (
      <ul className="nav navbar-nav navbar-nav-user float-right">
        <IntlContext.Consumer>
          {context => {
            return (
              <div className="dropdown d-inline-block" style={{ marginTop: "10px" }}>
                <UncontrolledDropdown>
                  <DropdownToggle color="flat-light" caret>
                    {/* <span style={{ color: "grey" }}>   {this.state.languagename}</span> */}
                    {/* <span style={{ color: "grey" }}>   {langArr[context.state.locale]}</span> */}
                    <span style={{ color: "grey" }}>   {this.state.dataSource ? this.state.dataSource.filter(t => t.shorttype == context.state.locale).map(e => { return e.languagename }) : ""}</span>
                    <ChevronDown size={15} />
                  </DropdownToggle>
                  <DropdownMenu>
                    {this.state.dataSource ? this.state.dataSource.map((i, index) => {
                      return (
                        <DropdownItem
                          tag="a"
                          onClick={e => {
                            context.switchLanguage(i.shorttype)
                            stringsoflanguages.setLanguage(i.shorttype)
                            localStorage.setItem('languagecode', i.languagecode)
                            localStorage.setItem('languagecode_short', i.shorttype)
                          }}
                        >
                          <span>{i.languagename}</span>
                        </DropdownItem>
                      )
                    }) : ""}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>


            )
          }}
        </IntlContext.Consumer>
        <div className="dropdown d-inline-block" style={{ marginTop: "10px" }}>
          <UncontrolledDropdown
          // tag="li"
          // className="dropdown-notification nav-item"
          >
            <DropdownToggle color="flat-light" caret>
              <span style={{ color: "grey" }}>   {stringsoflanguages.hori_joinus}</span>
              <ChevronDown size={15} />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem>
                <NavLink style={{ marginLeft: '5%' }}
                  onClick={() => {
                    window.open(this.state.youtubelink)
                  }}>
                  <div>
                    <i className='fa fa-youtube-play fa-1x ' style={{ color: '#c4302b', marginLeft: '1%' }}></i>
                    <span style={{ fontWeight: 10, fontSize: 15, marginLeft: '5%', color: 'black' }}>YouTube</span>
                  </div>

                </NavLink>
              </DropdownItem>
              <DropdownItem>
                <NavLink style={{ marginLeft: '5%' }} onClick={() => {
                  window.open(this.state.fblink)
                }}>
                  <div>
                    <i className='fa fa-facebook fa-1x ' style={{ color: '#3b5998' }}></i>
                    <span style={{ fontWeight: 10, fontSize: 15, marginLeft: '10%', color: 'black' }}>Facebook</span>
                  </div>

                </NavLink>
              </DropdownItem>
              <DropdownItem>
                <NavLink style={{ marginLeft: '5%' }} onClick={() => {
                  window.open(this.state.telegram_employer)
                }}>
                  <div>
                    <i className='fa fa-telegram fa-1x ' style={{ color: '#0088cc', marginLeft: '1%' }}></i>
                    <span style={{ fontWeight: 10, fontSize: 15, marginLeft: '3%', color: 'black' }}>Telegram</span>
                  </div>

                </NavLink>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>

        <div className="dropdown d-inline-block" style={{ marginTop: "10px" }}>
          <UncontrolledDropdown  >
            <DropdownToggle color="flat-light" caret   onClick={e => handleNavigation(e, "/profile/My_Profile")}>
              <span style={{ color: "grey" }}>   {stringsoflanguages.myaccount}</span> 
            </DropdownToggle>
            
          </UncontrolledDropdown>
        </div>
        <div className="dropdown d-inline-block" style={{ marginTop: "10px" }}>
          <UncontrolledDropdown  >
            <DropdownToggle color="flat-light" caret   onClick={() => {this.handleAlert("defaultAlert", true)}}>
              <span style={{ color: "grey" }}>   {stringsoflanguages.logout}</span> 
            </DropdownToggle>
            
          </UncontrolledDropdown>
        </div>
        <UncontrolledDropdown
          tag="li"
          className="dropdown-notification nav-item"
        >
          <DropdownToggle tag="a" className="nav-link nav-link-label">
            <i className='fa fa-bell-o fa-lg color-mycolor' onClick={() => this.notification_bell()}></i>
            {/* <Icon.Bell size={21} /> */}
            {this.state.overallcount_notification && this.state.overallcount_notification > 0 ?
              <Badge pill className="badge-up bg-mycolor">
                {" "}
                {this.state.overallcount_notification}
                {" "}
              </Badge> : ""}
          </DropdownToggle>
          <DropdownMenu tag="ul" right className="dropdown-menu-media">
            <li className="dropdown-menu-header notification-bg">
              <div className="dropdown-header mt-0">
                <h3 className="notify-title">You have {this.state.overallcount_notification}{stringsoflanguages.New_Notifications} </h3>
                {this.state.notification_list_news && this.state.notification_list_jobs && this.state.notification_list && this.state.notification_list_news.length === 0 && this.state.notification_list_jobs.length === 0 && this.state.notification_list.length === 0 ? "" :
                  <div className="dismiss-all cursor-pointer" onClick={() => this.dismiss_all()}>{stringsoflanguages.Dismiss_all}</div>}
              </div>
            </li>

            {/* <PerfectScrollbar
              className="media-list overflow-hidden position-relative"
              options={{
                wheelPropagation: false
              }}> */}

            <div
              className="notify-scrol"
            >
              <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId="1">

                  <Nav tabs>
                    <Row style={{ width: "100%" }}>
                      <Col md="4">
                        <NavItem >
                          <NavLink style={{ color: "#000000" }}
                            className={classnames({
                              active: this.state.active === "1"
                            })}
                            onClick={() => {
                              this.toggle("1")
                            }}
                          > <div>
                              {this.state.active != 1 && (this.state.jobs_invidual_count != "" || this.state.jobs_invidual_count != 0) ?
                                <Badge className="badge-news bg-mycolor-news">
                                  {this.state.jobs_invidual_count}
                                </Badge> : null} </div>
                            {stringsoflanguages.Job}
                          </NavLink>
                        </NavItem>
                      </Col>
                      <Col md="4">
                        <NavItem>
                          <NavLink style={{ color: "#000000 !important" }}
                            className={classnames({
                              active: this.state.active === "2"
                            })}
                            onClick={() => {
                              this.toggle("2")
                            }}
                          ><div>
                              {this.state.active != 2 && (this.state.news_invidual_count != "" || this.state.news_invidual_count != 0) ?
                                <Badge className="badge-new bg-mycolor-news">
                                  {this.state.news_invidual_count}
                                </Badge> : null} </div>
                            {stringsoflanguages.News}
                          </NavLink>
                        </NavItem>
                      </Col>
                      <Col md="4">
                        <NavItem>
                          <NavLink style={{ color: "#000000 !important" }}
                            className={classnames({
                              active: this.state.active === "3"
                            })}
                            onClick={() => {
                              this.toggle("3")
                            }}
                          ><div>
                              {this.state.active != 3 && (this.state.events_invidual_count != "" || this.state.events_invidual_count != 0) ?
                                <Badge className="badge-events bg-mycolor-news">
                                  {this.state.events_invidual_count}
                                </Badge> : null} </div>
                            {stringsoflanguages.Events}
                          </NavLink>
                        </NavItem>
                      </Col>
                    </Row>
                  </Nav>

                  <TabContent activeTab={this.state.active}>
                    <TabPane tabId="1">
                      {this.notification_view_list_jobs()}
                    </TabPane>
                    <TabPane tabId="2">
                      {this.notification_view_list_news()}
                    </TabPane>
                    <TabPane tabId="3">
                      {this.notification_view_list()}
                    </TabPane>
                  </TabContent>
                </TabPane>
              </TabContent>

            </div>

            {/* </PerfectScrollbar> */}
            <li className="dropdown-menu-footer">
              <DropdownItem tag="a" className="p-1 text-center text-color notification-dropdown">
                {/* <span href="#" className="read-notification">{stringsoflanguages.Dismiss_all} </span> */}
                <span onClick={() => this.readallnotify()} className="align-middle">{stringsoflanguages.viewallnoti}</span>
              </DropdownItem>
            </li>
          </DropdownMenu>
        </UncontrolledDropdown>

        <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
          <DropdownToggle tag="a" className="nav-link dropdown-user-link" >
            <div className="user-nav d-sm-flex d-none">
              <span className="user-name text-bold-600 color-mycolor">
                {this.state.employername_login}
              </span>
              {/* <span className="user-status">Available</span> */}
            </div>
            <span data-tour="user">
              <img
                src={this.state.varcompany_profileimg ? this.state.varcompany_profileimg : this.props.userImg}
                className="rounded-circle"
                height="40"
                width="40"
                alt="avatar"
              />
            </span>
          </DropdownToggle>
          {/* <DropdownMenu id="navbarid">
            <DropdownItem
              tag="a"
              href="#"
              onClick={e => handleNavigation(e, "/profile/My_Profile")}
            >
              <i className='color-myblack fa fa-user icon_style fa-lg'></i>
              <span className="align-middle">{stringsoflanguages.myaccount}</span>

            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem
              tag="a"
              //  href="/"
              onClick={() => {
                this.handleAlert("defaultAlert", true)
              }}
            >
              <i className='color-myblack fa fa-power-off icon_style fa-lg'></i>
              <span className="align-middle">{stringsoflanguages.logout}</span>
            </DropdownItem>
          </DropdownMenu>
          */}
          {/* <UserDropdown {...this.props} /> */}
          <SweetAlert title={stringsoflanguages.logout_msg}
            warning
            show={this.state.defaultAlert}
            showCancel
            reverseButtons
            confirmBtnBsStyle="submitalert-color"
            cancelBtnBsStyle="danger"
            confirmBtnText={stringsoflanguages.Yes}
            cancelBtnText={stringsoflanguages.Cancel}

            onConfirm={() => {
              this.logout();
            }}
            onCancel={() => {
              this.handleAlert("defaultAlert", false)
              this.handleAlert("confirmAlert", false)
            }}
          >

          </SweetAlert>

        </UncontrolledDropdown>
      </ul>
    )
  }
}
export default NavbarUser	