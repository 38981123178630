import React from "react"
import stringsoflanguages from '../Stringsoflanguage'
const navigationConfig = [
  {
    id: "dashboard",
    title: stringsoflanguages.hori_dashboard,
    type: "item",
    icon: <i className='fa fa-tachometer  icon_style'></i>,
    permissions: ["admin", "editor"],
    navLink: "/Dashboard"
  },

]

export default navigationConfig