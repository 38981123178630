module.exports = global.commonvariable = {
  getcommon: {
    variable: {
      apikey_map: "AIzaSyAcNVIX0ZSTMYUOGG7rgFZhKc0AWQ8xpt4", //Map Key

      registeredname: "",
      React_Pixel_ID: 338953408385690,

      razorpay_key: "rzp_test_K2bPquGpjHJZlR", //Test
      razorpay_secrect: "l3jioqyxcfr65u4q58lEZhSu", //Test

      // razorpay_key: "rzp_live_mMAlspl75PyduM", //Live
      // razorpay_secrect: "z9vjWyxjgaBpzbwqMR1gy49A", //Live

      //Terms & Conditions Link
      terms_condition_employer: "https://jobanya.com/terms-conditions/",
      numberonly: /^[0-9]*$/,
      employer_gstRegax:
        /^[[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}]*$/,
      default_language_code: 2,
      limitvalue: 25,
      skipvalue: 0,
      save_as_draft: 23,
      post_a_job: 4,
      wished: 10,
      unwished: 13,
      Accepted: 8,
      Rejected: 9,
      start_employer_list_arr: 0,
      end_employer_list_arr: 24,
      version: "1.0.28",
      timeset: 3000,
      jobvariableflag: 0,
      jobinvitedflag: 0,
      jobappliedflag: 0,
      jobshortlistedflag: 0,
      blocked_msg: "Your account is not active.please contact Jobanya admin",
      
    },
    // rest of your translation object
  },
  // other global config variables you wish
};

//Dev
// module.exports = global.commonvariable = {
//   getcommon: {
//     variable: {
//     //  GOOGLE_RECAPTCHA_SITE_KEY: '6Ld2baEaAAAAAKp_Lkng_9A4dQyFoIZNmBCOlsxp',  //Localhost
 
//  GOOGLE_RECAPTCHA_SITE_KEY: "6Let7h4pAAAAACWzL1_Et3GqstnoUw2XjEvaxb0g", //UAT test
     
 
//  GOOGLE_RECAPTCHA_SECRECT_KEY: '6Let7h4pAAAAAAcClLRxvWALeT3yO6AxBHtwu-Lc',//UAT Secrect Key
//       // GOOGLE_RECAPTCHA_SITE_KEY: '6LfbfsAgAAAAADl4MuK42yB8JwMqXg8sQdkYoGiM',//Live jobanya
//       // GOOGLE_RECAPTCHA_SITE_KEY: '6LdsYuYnAAAAAKcxlxYFKGblxf-Ps13VNc7A-LWZ',//Test
//       // GOOGLE_RECAPTCHA_SECRECT_KEY: '6LdsYuYnAAAAAHGZBnNavM6FNetnnaliDUYyFzze',//tEST jobanya Secrect Key
//       //GOOGLE_RECAPTCHA_SECRECT_KEY: '6LfbfsAgAAAAAMFEZQwCSyhPyDJc2M4xQZmsS_OT',//Live jobanya Secrect Key

//       apikey_map: 'AIzaSyAcNVIX0ZSTMYUOGG7rgFZhKc0AWQ8xpt4', //Map Key

//       registeredname: '',
//       React_Pixel_ID: 338953408385690,

//       // razorpay_key: 'rzp_test_K2bPquGpjHJZlR',//Test
//       // razorpay_secrect: 'l3jioqyxcfr65u4q58lEZhSu',//Test

//       razorpay_key: 'rzp_live_mMAlspl75PyduM', //Live
//       razorpay_secrect: 'z9vjWyxjgaBpzbwqMR1gy49A', //Live

//       //Terms & Conditions Link
//       terms_condition_employer: 'https://jobanya.com/terms-conditions/',
//       numberonly: /^[0-9]*$/,
//       employer_gstRegax: /^[[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}]*$/,
//       default_language_code: 2,
//       limitvalue: 25,
//       skipvalue: 0,
//       save_as_draft: 23,
//       post_a_job: 4,
//       wished: 10,
//       unwished: 13,
//       Accepted: 8,
//       Rejected: 9,
//       start_employer_list_arr: 0,
//       end_employer_list_arr: 24,
//       version: '1.0.28',
//       timeset: 3000,
//       jobvariableflag: 0,
//       jobinvitedflag: 0,
//       jobappliedflag: 0,
//       jobshortlistedflag: 0,
//       blocked_msg: 'Your account is not active.please contact Jobanya admin',

//     }
//     // rest of your translation object
//   }
//   // other global config variables you wish
// }

