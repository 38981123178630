import axios from 'axios';
export function ERRORLOG(user, version, class_name, function_name, line_no, error) {
    try {
        window.fbq('track', 'Error Writing', { fb_ad_function: "Error Writing", fb_ad_type: "Errorlog", fb_ad_loginuser: global.commonvariable.getcommon.variable.registeredname });
        axios({
            method: 'Post',
            url:  global.config.geturl.ipaddress.url + '/errorwriting/',
            params: {
                typecode: 4
            },
            data: {
                "usercode": user,
                "version": version,
                "classname": class_name,
                "functionname": function_name,
                "lineno": line_no,
                "error": error
            }
        })
            .then(response => {
                // console.log(error.toString())
                // console.log(function_name.toString())
                var result = response.data.error_json_result.varstatuscode;
                if (result === 20) {
                    // console.log(response.data.error_json_result.responsestring)
                }
                else {
                    // console.log(response.data.error_json_result.responsestring)
                }
            })
    }
    catch (error) {
        console.log(error)
    }
};