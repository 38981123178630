import LocalizedStrings from 'react-localization';
const strings = new LocalizedStrings({
    "en": {
        finalist_text: "Finalist",
        also_experience: '( Also has experience as ',
        also_experience1: ' )',
        reject_confirm_text: "Rejection Confirmation",
        reject_remarks_text: "Rejection Remarks",
        calls_made: "Calls Made",
        calls_received: "Calls Received",
        campany_photos: "Company Photos",
        job_preferences:"My Job Preferences",
        mobilenoexists:"Mobile no. already exists",
        per_month: "Per Month",
        last_login: "Last Login",
        employer_editable: "(Editable)",
        employer_noneditable: "(Non-Editable)",
        any_state: " Any State",
        any_district: " Any District",
        any_taluk: " Any Taluk",
        Additional_Documents: "Additional Documents",
        doument_error: "Please Upload atleast one Proof",
        Document_total_error: "Please upload atleast three documents",
        Document_type: "Document Type",
        Reference_number: "Reference Number",
        Reference_number_error: "Please enter Reference Number",
        Document_type_Error: "Please Select Document Type",
        Rejected_job: "This job was rejected",
        please_choose_district: 'Please choose any one of district',
        no_rec_taluk: 'No Record Found',
        text_viewed: 'Viewed',
        Match_pro: "Matching Profiles",
        employer_profile_txt: 'Upload Your company logo to make Your Profile Interesting !',
        employer_gallery_txt: 'Upload Your company Photos to make Your Profile Interesting !',
        abuse_txt: "Report Abuse",
        jobpostlist_hint: "Click your Listed Job Post, to view the Related Candidate Profiles",
        cart_text: 'Buy Now',
        click_to: 'Click to',
        post_job: '+ Post a job',
        post_job_btn: 'Post the Job',
        save_as_draft: 'Save as draft',
        jobpost_approval_info: ' Please Note: A Job Post cannot be Edited, except for the HR Contact Details, once the Job Post is Approved & Posted',
        employer_description: "To Avoid Job Post Rejection do not include contact information in Job Description",
        total_no_profile: "Total Profiles Count : ",
        exp_year_str: "Years",
        exp_year_str1: "Year",
        exp_month_str: "Months",
        exp_month_str1: "Month",
        subscriptions_buy_notes: 'KINDLY SUBSCRIBE THE PLANS AFTER SEEING SUFFICIENT MATCHING PROFILES In "My Preferred Candidates"',
        subscription_failed: "Subscription has failed",
        gst_applicable: "( GST as applicable )",
        gst_applicable1: "* GST as applicable",
        comparsion_table: "Comparison Table",
        plan_name: "Plan Name",
        price_in_month: "Price in Rupees / Plan",
        no_of_post: "No. of Job Posts",
        plan_validity: "Plan Validity in Days",
        max_validity_post: "Maximum Validity per Job Post,From Date of Posting",
        txt_back: "Back",
        txt_edit: "Edit",
        txt_add: "Add",
        any_degree: "Any Education Category",
        upto: 'Upto ',
        any_spec: 'Any Specialization',
        any_qual: 'Any Qualification',
        employer_reg_msg: ' Your registration has been submitted successfully. Please verify your Email ID by clicking email verification link sent to your registered Email Id.',
        keep_document_ready: "KEEP THESE DOCUMENT NUMBERS READY",
        keep_document_ready1: "# Company GST   # Company PAN   # Udyam / Udyog Aadhar",
        keep_document_ready2: "in",
        keep_document_ready3: "JPG/JPEG/PDF Format",
        keep_document_ready4: "FOR REGISTRATION",
        as_per_settings: 'My Employee Preference Settings',
        Social_Media_link: "Social Media Links",
        from_age: 'From age',
        to_age: 'To age',
        from_sal: 'From Salary',
        to_sal: 'To Salary',
        from_exp: 'From Experience',
        to_exp: 'To Experience',
        employer_preference_word: "While selecting preferences BE VERY SPECIFIC so that you will receive THE BEST TALENT IN YOUR CRITICAL DOMAINS",
        employee_preference_word: "While selecting preferences BE VERY SPECIFIC so that you will receive PERFECT JOB NOTIFICATIONS and JOB  RECOMMENDATIONS",
        applyaccept: 'Are you sure want to accept ?',
        applydecline: 'Are you sure want to decline ?',
        invite_qus: 'Are you sure want to Invite ?',
        invite_confirmQus:'Invited Successfully. Now you can call this Employee @ ',
        call_confirm:'You can call this Employee @ ',
        valid_address: 'Please enter valid address',
        branchname_valid: 'Please enter valid branch name',
        valid_name: 'Please enter valid name',
        valid_turnover: 'Please enter valid turnover',
        valid_noof: 'Please enter valid noofemployee',
        valid_desigination: 'Please enter valid designation',
        Similar_search: 'View Similar Matching Profiles',
        Exact_search: 'View exact matching profiles',
        Use_filter: 'Use Filters on the Left to Shortlist Profiles',
        Not_very_specific: "(Not Very Specific)",
        Subscribed_Successfully: 'Subscribed Successfully',
        location_exceed: 'Location exceeds limit',
        years_ago: "years ago",
        year_ago: 'year ago',
        months_ago: 'months ago',
        month_ago: 'month ago',
        days_ago: 'days ago',
        day_ago: 'day ago',
        hours_ago: 'hours ago',
        hour_ago: 'hour ago',
        minutes_ago: 'min ago',
        seconds_ago: 'seconds ago',
        week_ago: 'week ago',
        blocked_msg: "Your account is not active.please contact Jobanya admin",
        invite_success: "Invited Successfully",
        invite_error: "Already Invited",
        alrdy_applied: "Already Applied",
        invite_count_exceeds: "Invited limit is exceed",
        already_reject_job: "Employee Rejected this job",
        you_reject_this_job: "You Reject this Job",
        repost_success: "Reposted Successfully",
        clone_success: "Cloned Successfully",
        updated_success: "Updated Successfully",
        deleted_success: "Deleted Successfully",
        deleted_error: "Must have atleast one branch",
        saved_success: "Saved Successfully",
        abouse_success: "Reported as abuse successfully",
        abouse_error: "Already Abused",
        job_accept: "Job Application Accepted",
        job_reject: "Job Application rejected",
        job_accept_already: "Job application already accepted",
        job_reject_already: "Job application already rejected",
        shortlist_limit_exceed: "Shortlist limit is exceed",
        otp_sent: "OTP sent",
        otp_message: "Invalid OTP",
        email_changed: 'E Mail Changed Successfully',
        email_exists: 'E Mail is Already exists',
        gstin_already_exits: 'GSTIN Already exists',
        panno_already_exits: 'PAN No. Already exists',
        aadharno_already_exits: 'Aadhar No. Already exists',
        otp_message_expired: 'Expired OTP',
        password_old_check: "Old password is incorrect",
        psw_changed: 'Password Changed Successfully',
        deactivated_acc: "Your Account is Deactivated",
        reg_email_error: "Please enter registered email",
        otp_verified: 'Verified Success',
        Invalid_Mail: 'Invalid E Mail',
        id_psw_incorrect: "Please enter valid email ID and password",
        register_success: "Registered Successfully",
        logout_msg: "Are you sure, you want to Logout ?",
        New_Notifications: 'New Notifications',
        //horizontalmentconfig.js
        hori_dashboard: "Dashboard",
        hori_myjobs: "My Jobs",
        hori_subscription: 'My Subscriptions',
        hori_newsandevents: "News & Events",
        hori_contactus: 'Contact Us',
        hori_joinus: 'Join Us',
        hori_searchprofiles: 'General Profile Search Without Your Employee Preference Settings',
        hori_searchprofiles_split1: 'General Profile Search ',
        hori_searchprofiles_split2: ' Without ',
        hori_searchprofiles_split3: 'Your Employee Preference Settings',
        err_valid: "Please enter valid details",
        //login:
        erremail: "Please enter email",
        errvalidemail: "Please enter valid email",
        errpassword: "Please enter password",
        errvalidpassword: "Please enter valid password",
        errnewpassword: "Please enter new password",
        erroldpassword: "Please enter old password",
        //change email
        err_currentemail: "Please enter current email",
        err_newemail: "Please enter new email",
        err_getotp: "Please enter OTP number",
        err_validcurrentemail: "Please enter valid email",
        err_validnewemail: "Please enter valid email ",
        //profile info
        errprofile_industryname: "Please select industryname",
        errprofile_companytypename: "Please select companytypename",
        errprofile_employertypename: "Please select employertypename",
        registeredname: "Please enter registeredname",
        //company info
        erraboutcompany: "Please enter aboutcompany",
        errturnover: "Please select companyturnover",
        errnoofemployees: "Please enter noofemployees",
        errcompanybenefits: "Please select companybenefits",
        //preference
        errpref_jobfunction: "Please select jobfunction",
        errpref_jobrole: "Please select jobrole",
        errpref_joblocation: "Please select joblocation",
        errpref_taluk: "Please Select Taluk",
        //contactinfo
        errcontact_state: "Please select state",
        errcontact_district: "Please select district",
        errcontact_city: "Please enter  Town / City name",
        errvarpincode: "Please enter valid pincode",
        errvarcontact_address: "Please enter companyaddress",
        errvarcontact_phoneno_invalid: "Please enter mobilenumber",
        errvarcontact_landline_invalid: "Please enter Phonenumber",
        errvarcontact_Website_invalid: "Please enter website",
        //branch
        errbranch_companyaddress: "Please enter company address",
        errbranch_pincode: "Please enter valid pincode",
        errbranch_city: "Please enter Town / City name",
        errbranch_selectedstate: "Please select state",
        errbranch_selectedbranchtype: "Please select branch type",
        errbranch_selecteddistrict: "Please select district",
        errbranch_telephone: "Please enter phone number",
        errbranch_mobileno: "Please enter mobile number",
        errbranch_designation: "Please enter designation",
        errbranch_contactperson: "Please enter contactperson name",
        errbranch_name: "Please enter branch name",
        errbranchemail_invalid: "Please enter email",
        //contact_us
        errmessage: "please enter message",
        errsubject: "please select subject",
        ass_call: "For Assistance Call +91 9629938054",
        timeset: "3000",
        //New job post
        err_invalidBranch: "Please select branch",
        err_invalidEducationcategory: "Please select education category",
        err_invalidQualification: "Please select qualification",
        err_invalidSpecialization: "Please select specialization",
        err_invalidJobFunction: "Please select jobfunction",
        err_invalidJobRole: "Please select jobrole",
        err_invalidSkills: "Please select skill",
        err_invalidExperience: "Please Choose experience",
        err_invalidJobType: "Please select jobtype",
        err_invalidWorkTiming: "Please enter workingtime",
        err_invalidNoofOpenings: "Please enter no. of openings",
        err_invalidFacilities: "Please select facilities",
        err_invalidLastDate: "Please select last date",
        err_invalidDescription: "Please enter desceiption",
        err_invalidContactPersonName: "Please enter name",
        err_invalidEmailId: "Please enter valid emailid",
        err_invalidMobileno: "Please enter valid mobileno",
        err_valid_number: "Please enter valid number",
        err_valid_telephone: "Please enter valid Office number",
        valid_company_add: "Please enter valid company address",
        err_invalidLanguage: "Please select Language",
        err_invalidGender: "Please select Gender",
        err_invalidMartial: "Please select Martial Status",
        err_invalidAgeTo: "Please enter Ageto",
        err_invalidAgeFrom: "Please enter AgeFrom",
        err_invalidexpFrom: "Please enter Experience",
        err_invalidexpTo: 'Please enter valid Experience',
        err_invalidSalaryMax: 'Please enter valid maximum salary',
        // err_invalidSalaryMin: 'Please enter valid minimum salary',
        err_preferences_max_min_values: "Please enter valid min. salary",
        err_preferences_min_max_values: "Please enter valid max. salary",
        err_invalidSalaryMin: "Minimum salary should be minimum 4 digits",
        //subscription contact
        errtxt_noofposts: "Please enter no. of jobs allowed for the package",
        errtxt_jobvacancies: "Please enter no. of vacancies allowed for the job post",
        errtxt_profile: "Please enter no. of profiles to be viewed by the employer",
        errdescription: "Please enter description",
        err_invaliddesignation: "Please enter designation",
        err_invalidaddress: "Please enter addresss",
        //signup
        errstate: "Please select the state",
        errdistrict: "Please select the district",
        errindustry_type: "Please select the industry / sector",
        errdp_employmenttype: "Please select the employer type",
        erractivity_type: "Please select the activity type",
        errcity: "Please enter the Town / City name  ",
        errcompany: "Please enter company name",
        errtxt_email: "Please enter email",
        errtxt_mobileno: "Please enter mobile number",
        errcompanyname: "Please enter company name",
        errpassword: "Please enter password",
        errtelephone: "Please enter telephone number",
        errwebsite: "please enter website",
        erraddress: "Please enter addresss",
        errdesignation: "Please enter the designation",
        errgstin: "Please enter GSTIN Number",
        errpan: "Please enter Pan Number",
        erraadhar: "Please enter Aadhar Number",
        err_idproof: "Please Upload atleast three id proof",
        errAadharno: "Please enter Udyam/Udyog Aadhar Number",
        errfacility: "please select faclity offered",
        errknowntype: "Please select knowntype",
        errdocgstin: "Please Upload Document PDF Only",
        errAadharnodoc: "Please Upload Document PDF Only",
        errfileupload: "File size must be less the 2MB",
        errAddtiondoc: "Please Upload Document PDF Only",
        errpandoc: "Please upload Document",
        errfileempty: "Please upload file",
        errmobilevalid: "please enter valid mobile number",
        errgstinvalid: "Enter valid gstin number",
        errpaninvalid: "Enter valid Pan number",
        errAadharnoinvalid: "Enter valid Udyam/Udyog Aadhar number",
        errAadharnoinvalidprof: "Enter valid Aadhar number",
        errcompanytype: "Please select the company type",
        errcontact: "Please enter contact person",
        errothers: "please enter others",
        errotp: "Please enter otp nmuber",
        errvalidotp: "Please enter valid otp number",
        errconfirmpassword: "Please enter confirm password",
        err_confirmpassword: "Confirm password does not match",
        erruserlist: "Please select the user",
        errpasswordlength: "Please enter atleast 6 characters password",
        errtxt_pincode: "Please enter valid pincode",

        contact_add_same: "Contact address is same as company registered address",
        //Button Text
        Save: 'Save',
        //Navbaruser.js
        viewallnoti: 'View all notifications',
        No_Notifications_yet: 'No Notifications yet',
        myaccount: 'My Account',
        logout: "Logout",
        //Addeventbutton.js
        add: 'Add',
        //Addeventsidebar.js & calendar.js
        Business: 'Business',
        Work: 'Work',
        Personal: 'Personal',
        Others: 'Others',
        //calendar.js
        Month: 'Month',
        Week: 'Week',
        Day: 'Day',
        //Contact_us.js
        Subject: 'Subject',
        Message: 'Message',
        //Dashboard.js,
        prefered_candidates: 'My preferred candidates (As per',
        prefered_candidates_my: "My Employee Preference",
        prefered_candidates_last: "Settings)",
        Dashboard: 'Dashboard',
        Job_Posts: 'Job Posts',
        news_event: 'News & Events',
        //Dashboardsidebar.js
        apply_filter: 'APPLY FILTERS',
        Filter_By: 'Filter By',
        reset_filter: "RESET FILTERS",
        clear_filter: "CLEAR FILTERS",
        //Employee_search.js
        Job_Location: 'Job Location',
        Job_Location1: 'Employee Job Preference Location',
        Job_Taluk: 'Employee Job Preference Taluk',
        candidate_Location: 'Employee Home Location',
        candidate_Taluk: 'Employee Home Taluk',
        Job_Function: 'Job Function',
        Job_Role: 'Job Role',
        Skills1: 'Skills',
        Skills: 'Job Function, Job Role & Skills',
        Job_Type: 'Job Type',
        Qualification: 'Qualification',
        Experience: 'Experience',
        Martial_Status: 'Martial Status',
        Gender: 'Gender',
        Salary_Range: 'Salary Range',
        Age: 'Age',
        Differently_Abled: 'Differently Abled',
        search_placeholder_employeesearch: 'Search by Location, Job Function   ...',
        Recent_Search: 'Recent Search',
        Suggestion_Result: 'Suggestion Result',
        Sort_By: 'Sort By',
        employer_sort_by: 'Sort Candidates Search By',
        Load_More: 'Load More...',
        //Archived.js
        No_record: 'No records found',
        Expired_On: 'Expires On:',
        Any_location: 'Any Location',
        Years: 'Years',
        to: 'to',
        Expired: 'Expired',
        Applied: 'Applied',
        Invited: 'Invited',
        //Shortlisted: 'Shortlisted',
        Repost: 'Repost',
        Clone: 'Clone',
        Archived: 'Archived',
        //Employee_applied.js
        No_Record_found: 'No record found',
        Fresher: 'Fresher',
        Year: 'Year',
        Applied_On: 'Applied On : ',
        Accepted_On: 'Accepted On : ',
        Rejected_On: 'Rejected On : ',
        //Employee_list.js
        Shortlist_On: 'Finalist On : ',
        Invited_On: 'Invited On : ',
        call_now:'Call Now',

        // Profile summary
        profilesummary_title: "Available Profile Summary",
        my_pref_cand: "My Preferred Candidates",
        eligible_cand: 'Matching Profiles',
        invited_cand: 'Invited Candidates',
        applied_cand: "Applied candidates",
        invited_now: "Invite Now",
        posted_jobs: "Live Jobs",

        //Employee_profile_view.js
        at: 'at',
        Download: 'Download',
        Invite_Now: 'Call & Invite',
        Personal_Info: 'Personal Info',
        Father_Name: 'Father Name',
        Spouse_Name: 'Spouse Name',
        DOB: 'Date of Birth',
        aadhar: 'Udyam/Udyog Aadhar Number',
        any_medical: 'Any Known Medical History',
        Yes: 'Yes',
        No: 'No',
        All: 'All',
        Include: "Include",
        Only_Differently_Abled: "Only Differently Abled",
        Languages_Known: 'Languages Known',
        Not_avaliable: 'Available after subscription & shortlisting',
        Not_avaliable1: 'Available after shortlisting',
        Not_updated: 'Not updated',
        Contact_Info: 'Contact Info',
        Education: 'Education',
        Time_for_Joining: 'Time for Joining',
        Immediate: 'Immediate',
        Expected_Salary: 'Expected Salary',
        Accept: 'Accept',
        Decline: 'Decline',
        //Employee_profile.js
        valid_website: 'Please enter valid website',
        Software_Developer: 'Software Developer',
        Reference: 'Reference (Recommended By)',
        Preferences: 'My Employee Preferences',
        jobfunction_currently: "Current position",
        //Employee_search.js
        //Job_details.js
        Any: 'Any',
        Not_Applicable: 'Not Applicable',
        Preference: 'My Employee Preferences',
        Marital_Status: 'Marital Status',
        Age_Criteria: 'Age Criteria',
        Languages: 'Languages',
        No_of_Openings: 'No. of Openings',
        Work_Timing: 'Work Timing',
        shift_name: 'Shift Name',
        start_time: 'Starttime',
        end_time: 'Endtime',
        Hours: 'Hours',
        Minutes: 'Minutes',
        worktiming: 'please add work timing',
        shiftname_exits: 'Shift Name Already Exist',
        starttime_exits: 'Time Already Exist',
        shift_error: 'Please select shift name',
        fromtimetext_error: 'please select time',
        totimetext_error: 'please select End time',
        fromtimetext_error1: 'plese select start time',
        starthours_error: 'Please select start hours',
        startminutes_error: 'Please select start minutes',
        endhours_error: 'Please select end hours',
        endminutes_error: 'Please select end minutes',
        sametime_error: 'Please select the valid time',
        totimetext_error1: 'please select End time',
        About_Company: 'About Company',
        Activity_Type: 'Activity Type',
        Company_Type: "Company Type",
        Industry_Type: 'Industry / Sector ',
        Turn_over: 'Turn over',
        No_of_Employees: 'No.of Employees',
        Facilities_Offered: 'Facilities Offered',
        Interview_Location: 'Interview Location',
        //Jobpost_view
        Loading: 'Loading...',
        My_Jobs: 'My Jobs',
        New_Job_Post: 'New Job Post',
        My_Job_Posts: 'My Job Posts',
        Posted_On: 'Posted On',
        approved_on: 'Approved On',
        Job_Details: 'Job Details',
        Search_Profiles: 'Search Profiles',
        Matching_Profiles: 'Matching Profiles',
        Applied: 'Applied',
        Shortlisted: 'Finalist',
        Wishlist: 'Shortlist',
        //Myjobpost.js
        no_job_post: 'No active job post found. clickhere to add',
        new_Job_Post: 'new job post',
        Days_Left: 'Days Left',
        Expiring_Today: 'Expiring Today',
        Expiring_Soon: "Expiring Soon",
        Pending: 'Pending',
        Approved: 'Approved',
        Rejected: 'Rejected',
        SaveasDraft: 'Save as Draft',
        On: 'On',
        Off: 'Off',
        //Newjobpost.js
        Add_Work_timing: 'Add Work Timing',
        Shift_name: 'Shift Name',
        Language: 'Language',
        Branch: 'Branch',
        Educationcategory: 'Education Category',
        Specialization: 'Specialization',
        From: 'From',
        To: 'To',
        No_of_Openings: 'No of Openings',
        Last_Date: 'Last Date',
        Description: 'Description',
        Job_Group: "Job Group",
        Specific: "Specific",
        Preferred_Candidate_Location: 'Candidate Home Location',
        Preferred_Job_Location: 'Candidate Job Preference Location District',
        Preferred_Job_Taluk: 'Candidate Job Preference Location Taluk',
        Cand_home_location: 'Candidate Home Location District',
        Cand_home_taluk: 'Candidate Home Taluk',
        State: 'State',
        District: 'District',
        Taluk: 'Taluk',
        City: "Town / City",
        HR_Contact_Details: 'HR Contact Details',
        Contact_Person_Name: 'Contact Person Name',
        Designation: 'Designation',
        Email_ID: 'Email ID',
        Mobile_No: 'Mobile No. with WhatsApp',
        Alternative_Contact_No: 'Alternative Contact No.',
        Address: 'Address',
        Map_Location: 'Map Location',
        Latitude: 'Latitude',
        Longitude: 'Longitude',
        Search_Places: 'Search Places ...',
        //Events.js
        Events: 'Events',
        Link: 'Link',
        Venue: 'Venue',
        Date: 'Date',
        Expirydate: 'Expirydate',
        //NewsEventsSidebar.js
        News: 'News',
        website: 'www.vinmeeninfotech.com',
        Contact_Us: 'Contact Us',
        Login_With_Auth0: 'Login With Auth0',
        Email: 'Email',
        Password: 'Password',
        Forgot_Password: 'Forgot Password?',
        Register: 'Register',
        Login: 'Login',
        rememberme: 'Remember me ',
        OR: 'OR',
        email_pending: "Email id verification is in pending. Please check your mail box.",
        acc_blocked: "Your account has been blocked.please contact Jobanya admin.",
        waiting_for_approval: "Your registration is waiting for approval. For any clarification please contact Jobanya.",
        new_employer: 'New Employer?',
        Sign_Up: 'Sign Up',
        English: 'English',
        tamil: 'தமிழ்',
        Your_account_is_deactivated: 'Your account is deactivated',
        Activate_acc: 'Are you Sure want to activate your account?',
        //Register.js
        Create_Account: 'Create Account',
        create_new_acc: 'Fill the below form to create a new account.',
        Firebase: 'Firebase',
        Auth0: 'Auth0',
        JWT: 'JWT',
        //RegisterAuth0.js
        Register_With_Auth0: 'Register With Auth0',
        Name: 'Name',
        Confirm_Password: 'Confirm Password',
        //Activate_email.js
        reg_email: 'Please enter your registered email address',
        Back_to_Login: 'Back to Login',
        Get_OTP: 'Get OTP',
        Verify_OTP: 'Verify Email with OTP',
        //Forgotpassword.js
        Recover_your_password: 'Recover your password',
        forgot_msg: " Please enter your email address and we'll send you instructions on how to reset your password.",
        Recover_Password: 'Recover Password',
        //Get_otp.js
        enter_otp: 'Please enter your OTP',
        OTP: 'OTP',
        Resend_OTP: 'Resend OTP',
        Submit: 'Submit',
        Verified: 'Verified',
        //Lockscreen.js
        session_lock: 'Your Session is locked',
        Username: 'Username',
        Unlock: 'Unlock',
        not_john: 'Are you not John Doe ?',
        Reset_Password: 'Reset Password',
        new_password: 'Please enter your  new password to',
        continue: 'continue.',
        New_Password: 'New Password',
        //Resetpassword.js
        paratag: 'Please enter your email address and new password to',
        go_back_login: 'Go Back to Login',
        Reset: 'Reset',
        //Signup.js
        Employer_Registration: 'Employer Registration',
        Registered_Company_Name: 'Registered Company Name',
        Company_Type: 'Company Type',
        city_town: 'Town / City Name',
        industry_sector: 'Industry / Sector',
        Employer_Type: 'Employer Type',
        Registered_Company_Address: 'Registered Company Address',
        Company_Address: 'Company Address',
        Company_Address_Placeholder: '(Door Number, Street Name, Locality)',
        Website: 'Website',
        Pincode: 'Pincode',
        reg_email_signup: 'Registered Email ID',
        Mobile_Number: 'Mobile Number with WhatsApp',
        Telephone: 'Office Number',
        Contact_Person: 'Contact Person',
        know_bestjobs: 'How do you know about Jobanya?',
        GSTIN1: 'GSTIN',
        GSTIN: 'Company GSTIN ',
        PAN: 'Company PAN',
        PAN_no: 'PAN',
        documentvalidate:'Please Provide Three Valid Registration Numbers Without Attachments \n(or) One Valid Registration Number with Attachment',
        Aadhar_No: 'Udyam/Udyog Aadhar Certificate',
        Aadhar_no: 'Aadhar No.',
        i_agree: 'I agree to Jobanya',
        terms_and_conditions: 'Terms and Conditions',
        Prev: 'Prev',
        thank_contactus: 'Thank you for Contacting Us',
        reg_success: 'Thank you for your registration!',
        reg_success_one: 'Please check your inbox/spam for email verification. Once your email is verified, you are ready to login to the employer portal.',
        Click_here_to_Login: 'Click here to Login',
        View: 'View',
        View_Contact: 'View Contact',
        reg_success1: 'Your registration has been submitted successfully.',
        reg_success2: 'Please verifiy your Email ID by clicking email verification link  sent to your registered Email id.',
        Ok: 'Ok',
        //change_password.js
        Change_Password: 'Change Password',
        Old_Password: 'Old Password',
        psw_success: 'Your password has been changed successfully. You will be forced to logout from the application. Login again',
        //Employer_profile.js
        Edit: 'Edit',
        Registered_on: 'Registered on',
        Approved_on: 'Approved on',
        Referals: 'Referals',
        Registered_office: 'Registered office',
        Government_Identification: 'Government Identification',
        Annual_Turn_Over: 'Annual Turn Over',
        Total_Employees: 'Total Employees',
        Facility_Offered: 'Facility Offered',
        Photo_Gallery: 'Photo Gallery',
        No_record_found: 'No record found',
        Branches: 'Head Office & Branch Details',
        Branches1: 'Please Add your H.O. as Default Branch',
        Approval: 'Approval',
        Remarks: 'Remarks',
        Approve: 'Approve',
        Block: 'Block',
        Reject: 'Reject',
        UnBlock: 'UnBlock',
        Close: 'Close',
        Phone_No: 'Phone No. with WhatsApp',
        Update: 'Update',
        gallery: "Note: Maximum 3 Photos Allowed",
        Branch_Name: 'Branch Name',
        Branch_Type: 'Branch Type',
        Delete: 'Delete',
        update_preference_details: 'Update Preference Details',
        update_company_details: 'Update Company Details',
        Update_Branch_Details: 'Update Branch Details',
        About_company: 'About company',
        Turn_Over: 'Turn Over',
        no_of_employee: 'No. of employees',
        Update_Profile_Details: 'Update Profile Details',
        Registered_name: 'Registered Name',
        Industry: 'Industry',
        Employertype: 'Employer Type',
        Companytype: 'Company Type',
        Details: 'Details',
        My_Profile: 'My Profile',
        Settings: 'Settings',
        //Settings.js
        Change_Email_ID: 'Change Email ID',
        Current_Email_ID: 'Current Email ID',
        New_Email_ID: 'New Email ID',
        Notification: 'Notification',
        Invisible_Mode: 'Invisible Mode',
        Invisible_Mode_one: 'Employee Visible Account / Invisible Account',
        Invisible_Mode_1: 'Are you Sure want to change your account to invisible mode?',
        Cancel: 'Cancel',
        Dismiss_all: 'Dismiss all',
        Job: 'Job',
        Notifications: 'Notifications',
        //Search_profile.js
        isany: 'Any',
        //subscription
        no_active: 'No active subscription found. clickhere to',
        subscribe: 'subscribe',
        no_of_jobpost: 'No. of  Job Posts',
        no_of_Vacancies: 'No. of  Vacancies / Post',
        no_of_view: 'No. of Profiles who can  View / Post',
        no_of_applied: 'No. of Profiles who can  Apply / Post',
        no_of_invited: 'No. of Profiles who can be Invited / Post',
        no_of_shortlisted: 'No. of Profiles who can be Shortlised / Post',
        Total: 'Total',
        Posted: 'Posted',
        Posted_one: 'Posted',
        Available: 'Available',
        Active: 'Active',
        Days: 'Days',
        max_validity: 'Maximum Validity per Job Post,From Date of Posting',
        Expires_on: 'Expires on ',
        Select: 'Select',
        No_of_Profiles: 'No. of Profiles',
        No_of_Vacancies: 'No. of Vacancies / post',
        No_of_Posts: 'No. of Posts',
        Subscribed_on: 'Subscribed on',
        Unlimited: 'Unlimited',
        Expired_on: 'Expires On',
        Plan_Validity_on: 'Plan Validity',
        Custom_Plan: 'Custom Plan',
        more_plan: 'Are you looking for more number of Job posts and profiles ?',
        My_Subscriptions: 'My Subscriptions',
        History: 'History',
        Subscribe: 'Subscribe',
    },

    "ta":
    {
        finalist_text: "இறுதிப் போட்டியாளர்கள்",
        also_experience:'( ',
        also_experience1:' அனுபவம் உண்டு )',   
        reject_confirm_text: "நிராகரிப்பு உறுதிப்படுத்தல்",
        reject_remarks_text: "நிராகரிப்பு குறிப்புகள்",
        calls_made: "அழைப்புகள் செய்யப்பட்டன",
        calls_received: "அழைப்புகள் பெறப்பட்டன",
        years_ago: "ஆண்டுகளுக்கு முன்பு",
        year_ago: 'ஆண்டுக்கு முன்பு',
        months_ago: 'மாதங்களுக்கு முன்பு',
        month_ago: 'மாதத்திற்கு முன்பு',
        days_ago: 'நாட்களுக்கு முன்பு',
        day_ago: 'நாள் முன்பு',
        hours_ago: 'மணி நேரத்திற்க்கு முன்பு',
        hour_ago: 'மணி நேரம் முன்பு',
        minutes_ago: 'நிமிடம் முன்பு',
        seconds_ago: 'விநாடிகளுக்கு முன்பு',
        week_ago: 'வாரத்திற்கு முன்பு',
        blocked_msg: "உங்கள் கணக்கு செயலில் இல்லை. ஜபான்யா நிர்வாகியை தொடர்பு கொள்ளவும்",
        invite_success: "வெற்றிகரமாக அழைக்கபட்டுள்ளீர்கள்",
        invite_error: "ஏற்கனவே அழைக்கப்பட்டது",
        alrdy_applied: "ஏற்கனவே விண்ணப்பிக்கப்பட்டது",
        invite_count_exceeds: "அழைப்பு வரம்பு மீறப்பட்டது",
        already_reject_job: "வேலை நாடுநர் இந்த வேலையை நிராகரித்தார்",
        you_reject_this_job: "நீங்கள் இந்த வேலையை நிராகரித்து உள்ளீர்கள்",
        repost_success: "வெற்றிகரமாக மறுபதிவு செய்யப்பட்டது",
        clone_success: "வெற்றிகரமாக குளோன் செய்யப்பட்டது",
        updated_success: "வெற்றிகரமாக புதுப்பிக்கப்பட்டது",
        deleted_success: "வெற்றிகரமாக அழிக்கப்பட்டது",
        deleted_error: "வேலையளிப்போர் குறைந்தபட்சம் ஒரு கிளையாவது வைத்திருக்க வேண்டும்",
        saved_success: "வெற்றிகரமாக சேமிக்கப்பட்டுள்ளது ",
        abouse_success: "துஷ்பிரயோகம் என வெற்றிகரமாக புகாரளிக்கப்பட்டது",
        abouse_error: "ஏற்கனவே துஷ்பிரயோகம் செய்யப்பட்டுள்ளது",
        job_accept: "வேலை விண்ணப்பம் ஏற்றுக்கொள்ளப்பட்டது",
        job_reject: "வேலை விண்ணப்பம் நிராகரிக்கப்பட்டது",
        job_accept_already: "வேலை விண்ணப்பம் ஏற்கனவே ஏற்றுக்கொள்ளப்பட்டுள்ளது",
        job_reject_already: "வேலை விண்ணப்பம் ஏற்கனவே நிராகரிக்கப்பட்டுள்ளது",
        shortlist_limit_exceed: "தேர்தெடுத்தார் பட்டியல் வரம்பு மீறப்பட்டுள்ளது",
        otp_sent: "ஓடிபி வெற்றிகரமாக அனுப்பபட்டது",
        otp_message: "தவறான ஓடிபி",
        email_changed: 'மின்னஞ்சல் வெற்றிகரமாக மாற்றப்பட்டது ',
        email_exists: 'மின்னஞ்சல் ஏற்கனவே உள்ளது',
        gstin_already_exits: 'ஜிஎஸ்டி எண் ஏற்கனவே உள்ளது',
        panno_already_exits: 'பான் எண் ஏற்கனவே உள்ளது',
        aadharno_already_exits: 'ஆதார் எண் ஏற்கனவே உள்ளது',
        otp_message_expired: 'காலாவதியான ஒடிபி',
        password_old_check: "பழைய பாஸ்வேர்ட் தவறானது",
        psw_changed: 'பாஸ்வேர்ட் வெற்றிகரமாக மாற்றப்பட்டுள்ளது ',
        deactivated_acc: "உங்கள் கணக்கு செயலிழக்கப்பட்டது",
        reg_email_error: "பதிவுசெய்த மின்னஞ்சலை உள்ளிடவும்",
        otp_verified: 'வெற்றிகரமாக சரிபார்க்கப்பட்டது',
        Invalid_Mail: 'தவறான மின்னஞ்சல்',
        id_psw_incorrect: "செல்லுபடியாகும் மின்னஞ்சல் மற்றும் பாஸ்வேர்டை உள்ளிடவும்",
        register_success: "வெற்றிகரமாக பதிவு செய்யப்பட்டுள்ளது ",
        logout_msg: "நீங்கள் நிச்சயமாக வெளியேற விரும்புகிறீர்களா ?",
        New_Notifications: 'புதிய அறிவிப்புகள்',
        //horizontalmentconfig.js
        hori_dashboard: "டாஷ்போர்டு",
        hori_myjobs: "எனது வேலைகள்",
        hori_subscription: 'எனது சந்தாக்கள்',
        hori_newsandevents: "செய்திகள் & நிகழ்வுகள்",
        hori_contactus: 'எங்களை தொடர்பு கொள்ள',
        hori_joinus: 'எங்களுடன் சேர',
        hori_searchprofiles: 'உங்கள் பணியாளர் விருப்பத்தேர்வுகள் இல்லாமல் பொது சுயவிவர தேடல்',
        hori_searchprofiles_split1: 'உங்கள் விருப்பத்தேர்வுகள் ',
        hori_searchprofiles_split2: ' இல்லாமல் ',
        hori_searchprofiles_split3: 'பொது சுயவிவர தேடல்',
        err_valid: "சரியான விவரங்களை உள்ளிடவும்",
        //login:
        erremail: "மின்னஞ்சலை உள்ளிடவும்",
        errvalidemail: "செல்லுபடியாகும் மின்னஞ்சலை உள்ளிடவும்",
        errpassword: "பாஸ்வேர்டை உள்ளிடவும்",
        errvalidpassword: "செல்லுபடியாகும் பாஸ்வேர்டை உள்ளிடவும்",
        errnewpassword: "புதிய பாஸ்வேர்டை உள்ளிடவும்",
        erroldpassword: "பழைய பாஸ்வேர்டை உள்ளிடவும்",
        //change email
        err_currentemail: "தற்போதைய மின்னஞ்சலை உள்ளிடவும்",
        err_newemail: "புதிய மின்னஞ்சலை உள்ளிடவும்",
        err_getotp: "ஒடிபி எண்ணை உள்ளிடவும்",
        err_validcurrentemail: "செல்லுபடியாகும் மின்னஞ்சலை உள்ளிடவும்",
        err_validnewemail: "செல்லுபடியாகும் மின்னஞ்சலை உள்ளிடவும் ",
        //profile info
        errprofile_industryname: "தொழில்துறை பெயரைத் தேர்ந்தெடுக்கவும்",
        errprofile_companytypename: "நிறுவனத்தின் வகையை தேர்ந்தெடுக்கவும்",
        errprofile_employertypename: "வேலையளிப்போர் வகையை தேர்ந்தெடுக்கவும்",
        registeredname: "பதிவுசெய்யப்பட்ட பெயரை உள்ளிடவும்",
        //company info
        erraboutcompany: "நிறுவனம் பற்றி உள்ளிடவும்",
        errturnover: "நிறுவனத்தின் ஆண்டு விற்பனையை தேர்ந்தெடுக்கவும்",
        errnoofemployees: "வேலையாட்களின் எண்ணிக்கையை உள்ளிடவும்",
        errcompanybenefits: "நிறுவனத்தின் சலுகைளை தேர்ந்தெடுக்கவும்",
        //preference
        errpref_jobfunction: "வேலை வகையினத்தை தேர்ந்தெடுக்கவும்",
        errpref_jobrole: "வேலை பங்கை தேர்ந்தெடுக்கவும்",
        errpref_joblocation: "வேலை இடத்தை தேர்ந்தெடுக்கவும்",
        errpref_taluk: "தாலுக்கைத் தேர்ந்தெடுக்கவும்",
        //contactinfo
        errcontact_state: "மாநிலத்தை தேர்ந்தெடுக்கவும்",
        errcontact_district: "மாவட்டத்தை தேர்ந்தெடுக்கவும்",
        errcontact_city: "டவுன் / நகரத்தின் பெயரை உள்ளிடவும்",
        errvarpincode: "செல்லுபடியாகும் அஞ்சல் குறியீடை உள்ளிடவும்",
        errvarcontact_address: "நிறுவனத்தின் முகவரியை உள்ளிடவும்",
        errvarcontact_phoneno_invalid: "கைபேசி எண்ணை உள்ளிடவும்",
        errvarcontact_landline_invalid: "தொலைபேசி எண்ணை உள்ளிடவும்",
        errvarcontact_Website_invalid: "வலைதள முகவரியை உள்ளிடவும்",
        //branch
        errbranch_companyaddress: "நிறுவனத்தின் முகவரியை உள்ளிடவும்",
        errbranch_pincode: "செல்லுபடியாகும் அஞ்சல் குறியீடை உள்ளிடவும்",
        errbranch_city: "டவுன் / நகரத்தின் பெயரை உள்ளிடவும்",
        errbranch_selectedstate: "மாநிலத்தைத் தேர்ந்தெடுக்கவும்",
        errbranch_selectedbranchtype: "கிளை வகை தேர்ந்தெடுக்கவும்",
        errbranch_selecteddistrict: "மாவட்டத்தைத் தேர்ந்தெடுக்கவும்",
        errbranch_telephone: "தொலைபேசி எண்ணை உள்ளிடவும்",
        errbranch_mobileno: "கைபேசி எண்ணை உள்ளிடவும்",
        errbranch_designation: "பதவியை உள்ளிடவும்",
        errbranch_contactperson: "தொடர்பு கொள்ள வேண்டிய நபரின் பெயரை உள்ளிடவும்",
        errbranch_name: "கிளை பெயரை உள்ளிடவும்",
        errbranchemail_invalid: "மின்னஞ்சலை உள்ளிடவும்",
        //contact_us
        errmessage: "தகவலை உள்ளிடவும்",
        errsubject: "தலைப்பை தேர்ந்தெடுக்கவும்",
        ass_call: "உதவிக்கான அழைப்பு +91 9629938054",
        timeset: "3000",
        //New job post
        err_invalidBranch: "கிளையைத் தேர்ந்தெடுக்கவும்",
        err_invalidEducationcategory: "கல்வி வகையை தேர்ந்தெடுக்கவும்",
        err_invalidQualification: "தகுதியை தேர்ந்தெடுக்கவும்",
        err_invalidSpecialization: "நிபுணத்துவத்தை தேர்ந்தெடுக்கவும்",
        err_invalidJobFunction: "வேலை வகையினத்தை தேர்ந்தெடுக்கவும்",
        err_invalidJobRole: "வேலை பங்கை தேர்ந்தெடுக்கவும்",
        err_invalidSkills: "திறன்களை தேர்ந்தெடுக்கவும்",
        err_invalidExperience: "அனுபவத்தை தேர்ந்தெடுக்கவும்",
        err_invalidJobType: "வேலை வகையை தேர்ந்தெடுக்கவும்",
        err_invalidWorkTiming: "வேலை நேரத்தை உள்ளிடவும்",
        err_invalidNoofOpenings: "காலியிடங்களின் எண்ணிக்கையை உள்ளிடவும்",
        err_invalidFacilities: "வசதிகளை தேர்ந்தெடுக்கவும்",
        err_invalidLastDate: "கடைசி தேதியை தேர்ந்தெடுக்கவும்",
        err_invalidDescription: "தயவுசெய்து விளக்கம்ஐ உள்ளிடவும்",
        err_invalidContactPersonName: "பெயரை உள்ளிடவும்",
        err_invalidEmailId: "செல்லுபடியாகும் மின்னஞ்சல் முகவரியை உள்ளிடவும்",
        err_invalidMobileno: "செல்லுபடியாகும் மொபைல் எண்ணை உள்ளிடவும்.",
        err_valid_number: "செல்லுபடியாகும் எண்ணை உள்ளிடவும்",
        err_valid_telephone: "செல்லுபடியாகும் தொலைபேசியை உள்ளிடவும்",
        valid_company_add: "செல்லுபடியாகும் நிறுவனத்தின் முகவரியை உள்ளிடவும்",
        err_invalidLanguage: "மொழியை தேர்ந்தெடுக்கவும்",
        err_invalidGender: "பாலினத்தை தேர்ந்தெடுக்கவும்",
        err_invalidMartial: "திருமண நிலையை தேர்ந்தெடுக்கவும்",
        err_invalidAgeTo: "வயதை உள்ளிடவும்",
        err_invalidAgeFrom: "வயதை உள்ளிடவும்",
        err_invalidexpFrom: "அனுபவத்தை உள்ளிடவும்",
        err_invalidexpTo: 'செல்லுபடியாகும் அனுபவத்தை உள்ளிடவும்',
        err_invalidSalaryMax: 'செல்லுபடியாகும் அதிகபட்ச சம்பளத்தை உள்ளிடவும்',
        // err_invalidSalaryMin: 'செல்லுபடியாகும் குறைந்தபட்ச சம்பளத்தை உள்ளிடவும்',
        err_invalidSalaryMin: "குறைந்தபட்ச சம்பளம் குறைந்தபட்சம் 4 இலக்கங்களாக இருக்க வேண்டும்",
        //subscription contact
        errtxt_noofposts: "தொகுப்பிற்கான அனுமதிக்கப்பட்ட வேலைகளின் எண்ணிக்கையை உள்ளிடவும்",
        errtxt_jobvacancies: "வேலை இடுகைகான அனுமதிக்கப்பட்ட காலியிடங்களின் எண்ணிக்கையை உள்ளிடவும்",
        errtxt_profile: "வேலையளிப்போர் காணக்கூடிய சுயவிவரங்களின் எண்ணிக்கையை உள்ளிடவும்",
        errdescription: "விளக்கத்தை உள்ளிடவும்",
        err_invaliddesignation: "பதவியை உள்ளிடவும்",
        err_invalidaddress: "முகவரியை உள்ளிடவும்",
        //signup
        errstate: "மாநிலத்தை தேர்ந்தெடுக்கவும்",
        errdistrict: "மாவட்டத்தை தேர்ந்தெடுக்கவும்",
        errindustry_type: "தொழில்துறை / பிரிவை தேர்ந்தெடுக்கவும்",
        errdp_employmenttype: "வேலையளிப்போர் வகையை தேர்ந்தெடுக்கவும்",
        erractivity_type: "செயல்பாட்டு வகையை தேர்ந்தெடுக்கவும்",
        errcity: "டவுன் / நகரத்தின் பெயரை உள்ளிடவும்",
        errcompany: "நிறுவனத்தின் பெயரை உள்ளிடவும்",
        errtxt_email: "மின்னஞ்சலை உள்ளிடவும்",
        errtxt_mobileno: "கைபேசி எண்ணை உள்ளிடவும்",
        errcompanyname: "நிறுவனத்தின் பெயரை உள்ளிடவும்",
        errpassword: "பாஸ்வேர்டை உள்ளிடவும்",
        errtelephone: "தொலைபேசி எண்ணை உள்ளிடவும்",
        errwebsite: "வலைதள முகவரியை உள்ளிடவும்",
        erraddress: "முகவரியை உள்ளிடவும்",
        errdesignation: "பதவியை உள்ளிடவும்",
        errgstin: "ஜிஎஸ்டி எண் எண்ணை உள்ளிடவும்",
        errpan: "பான் எண்ணை உள்ளிடவும்",
        erraadhar: "ஆதார் எண்ணை உள்ளிடவும்",
        err_idproof: "குறைந்தபட்சம் இரண்டு ஆதாரத்தையாவது தேர்வு செய்யவும்",
        errAadharno: "உதயம்/உத்யோக் ஆதார் எண்ணை உள்ளிடவும்",
        errfacility: "வழங்கப்படும் வசதியை தேர்ந்தெடுக்கவும்",
        errknowntype: "தெரிந்த வகையை தேர்ந்தெடுக்கவும்",
        errdocgstin: "ஆவணத்தை பதிவேற்றவும் PDF",
        errAadharnodoc: "ஆவணத்தை பதிவேற்றவும் PDF",
        errfileupload: "கோப்பு அளவு 2MB க்கும் குறைவாக இருக்க வேண்டும்",
        errAddtiondoc: "ஆவணத்தை பதிவேற்றவும் PDF",
        errpandoc: "ஆவணத்தை பதிவேற்றவும்",
        errfileempty: "கோப்பை பதிவேற்றவும்",
        errmobilevalid: "செல்லுபடியாகும் கைபேசி எண்ணை உள்ளிடவும்",
        errgstinvalid: "செல்லுபடியாகும் ஜிஎஸ்டி எண் எண்ணை உள்ளிடவும்",
        errpaninvalid: "செல்லுபடியாகும் பான் எண்ணை உள்ளிடவும்",
        errAadharnoinvalid: "செல்லுபடியாகும் உதயம்/உத்யோக் ஆதார் எண்ணை உள்ளிடவும்",
        errAadharnoinvalidprof: "செல்லுபடியாகும் ஆதார் எண்ணை உள்ளிடவும்",
        errcompanytype: "நிறுவனத்தின் வகையை தேர்ந்தெடுக்கவும்",
        errcontact: "தொடர்பு கொள்ள வேண்டிய நபரை உள்ளிடவும்",
        errothers: "மற்றவர்களை உள்ளிடவும்",
        errotp: "ஒடிபி எண்ணை உள்ளிடவும்",
        errvalidotp: "செல்லுபடியாகும் ஒடிபி எண்ணை உள்ளிடவும்",
        errconfirmpassword: "பாஸ்வேர்டை உறுதிப்படுத்த உள்ளிடவும்",
        err_confirmpassword: "உறுதிப்படுத்தப்பட்ட பாஸ்வேர்ட் பொருந்தவில்லை",
        erruserlist: "பயனரை தேர்ந்தெடுக்கவும்",
        errpasswordlength: "குறைந்தது 6 எழுத்துகள் உள்ள பாஸ்வேர்டை உள்ளிடவும்",
        errtxt_pincode: "செல்லுபடியாகும் அஞ்சல் குறியீடை உள்ளிடவும்",
        contact_add_same: "தொடர்பு முகவரி நிறுவனத்தின் பதிவு செய்யப்பட்ட முகவரி போன்றது",
        //Button Text
        Save: 'சேமி',
        //Navbaruser.js
        viewallnoti: 'எல்லா அறிவிப்புகளையும் காண்க',
        myaccount: 'என் கணக்கு',
        logout: "வெளியேறு",
        //Addeventbutton.js
        add: 'சேர்க்கவும்',
        //Addeventsidebar.js & calendar.js
        Business: 'வணிகம்',
        Work: 'வேலை',
        Personal: 'தனிப்பட்ட',
        Others: 'மற்றவைகள்',
        //calendar.js
        Month: 'மாதம்',
        Week: 'வாரம்',
        Day: 'நாள்',
        //Contact_us.js
        Subject: 'பொருள்',
        Message: 'தகவல்',
        //Dashboard.js
        prefered_candidates: 'எனது விருப்பமான பணியாளர்கள் (எனது விருப்ப அமைப்புகளின்படி)',
        Dashboard: 'டாஷ்போர்டு',
        Job_Posts: 'வேலை இடுகைகள்',
        news_event: 'செய்திகள் & நிகழ்வுகள்',
        //Dashboardsidebar.js
        apply_filter: 'வடிப்பானைப் பயன்படுத்துங்கள்',
        Filter_By: 'வடிகட்டவும்',
        reset_filter: "வடிகட்டியை அழி",
        clear_filter: "வடிகட்டியை அழி",
        //Employee_search.js
        Job_Location: 'வேலை இடம்',
        Job_Location1: 'பணியாளர் தேடுவோர்  பணி விருப்ப இடம்',
        Job_Taluk: "பணியாளர் பணி விருப்ப தாலுகா",
        candidate_Location: 'பணியாளர் இல்லத்தின்  இருப்பிடம்',
        candidate_Taluk: 'பணியாளர் இல்லத்தின்  தாலுகா',
        Job_Function: 'வேலை வகையினம்',
        Job_Role: 'வேலை பங்கு',
        Skills1: 'திறன்கள்',
        Skills: 'வேலை வகையினம்,வேலை பங்கு & திறன்கள்',
        Job_Type: 'வேலை வகை',
        Qualification: 'தகுதி',
        Experience: 'அனுபவம்',
        Martial_Status: 'திருமண நிலை',
        Gender: 'பாலினம்',
        Salary_Range: 'ஊதிய வீதம்',
        Age: 'வயது',
        Differently_Abled: 'மாற்றுத்திறனாளி',
        search_placeholder_employeesearch: 'இருப்பிடம், வேலை வகையினம் ஆகியவற்றின் அடிப்படையில் தேடுங்கள்',
        Recent_Search: 'சமீபத்திய தேடல்',
        Suggestion_Result: 'பரிந்துரை முடிவு',
        Sort_By: 'வரிசைப்படுத்து',
        employer_sort_by: 'விண்ணப்பதாரர்களைத் தேடுவதன் மூலம் வரிசைப்படுத்தவும்',
        Load_More: 'அதிகமாக ஏற்று...',
        //Archived.js
        No_record: 'எந்த பதிவுகளும் கண்டறியப்படவில்லை',
        Expired_On: 'காலாவதியான நாள் :',
        Any_location: 'ஏதேனும் இடம்',
        Years: 'ஆண்டுகள்',
        to: 'வரை',
        Expired: 'காலாவதியானது',
        Applied: 'விண்ணப்பிக்கப்பட்டது',
        Invited: 'அழைக்கப்பட்டது',
        Shortlisted: 'இறுதித்தேர்வு',
        Repost: 'மறுபதிவுசெய்',
        Clone: 'போலிகை',
        Archived: 'ஆவண வகைப்படுத்தல் ',
        //Employee_applied.js
        No_record_found: 'எந்த பதிவுகளும் கண்டறியப்படவில்லை',
        Fresher: 'புதியவர்',
        Year: 'ஆண்டு',
        Applied_On: 'விண்ணப்பிக்கப்பட்ட நாள் : ',
        Accepted_On: 'ஏற்றுக்கொள்ளப்பட்ட நாள் : ',
        Rejected_On: 'நிராகரிக்கப்பட்ட நாள் : ',
        //Employee_list.js
        Shortlist_On: 'தேர்ந்தெடுக்கப்பட்ட நாள் : ',
        Invited_On: 'அழைக்கப்பட்ட நாள் : ',
        call_now:'இப்போது அழைக்கவும்:',

        // Profile summary
        profilesummary_title: "சுயவிவர சுருக்கம்",
        my_pref_cand: "பொருந்தும் சுயவிவரங்கள்",
        eligible_cand: 'பொருந்தும் சுயவிவரங்கள்',
        invited_cand: 'அழைக்கப்பட்ட விண்ணப்பதாரர்கள்',
        applied_cand: "விண்ணப்பித்த விண்ணப்பதாரர்கள்",
        invited_now: "இப்போது அழைக்கவும்",
        posted_jobs: "செயலில் உள்ள வேலைகள்",

        //Employee_profile_view.js
        at: 'இல்',
        Download: 'பதிவிறக்கு',
        Invite_Now: 'அழைத்து அழையுங்கள்',
        Personal_Info: 'தனிப்பட்ட தகவல்',
        Father_Name: 'தந்தையின் பெயர்',
        Spouse_Name: 'கணவன் அல்லது மனைவியின் பெயர்',
        DOB: 'பிறந்த தேதி',
        aadhar: 'உதயம்/உத்யோக் ஆதார் எண்',
        any_medical: 'அறியப்பட்ட மருத்துவ வரலாறு',
        Yes: 'ஆம்',
        No: 'இல்லை',
        All: "எல்லாம்",
        Include: "சேர்க்கவும்",
        Only_Differently_Abled: "மாற்றுத்திறனாளிகள் மட்டும்",
        Languages_Known: 'தெரிந்த மொழிகள்',
        Not_avaliable: 'சுருக்கப்பட்டியலுக்குப் பிறகு கிடைக்கும்',
        Not_avaliable1: 'Available after shortlisting',
        Not_updated: 'புதுப்பிக்கப்படவில்லை',
        Contact_Info: 'தொடர்பு கொள்வதற்கான தகவல்',
        Education: 'கல்வி',
        Time_for_Joining: 'சேருவதற்கான கால அவகாசம்',
        Immediate: 'உடனடியாக',
        Expected_Salary: 'எதிர்பார்க்கப்படும் சம்பளம்',
        Accept: 'ஏற்றுக்கொள்',
        Decline: 'மறுக்க',
        //Employee_profile.js
        valid_website: 'செல்லுபடியாகும் வலைத்தளத்தை உள்ளிடவும்',
        Software_Developer: 'மென்பொருள் உருவாக்குபவர்',
        Reference: 'என்னை பரிந்துரைக்கும் நபர்கள்',
        Preferences: 'என் பணியாளர் விருப்பத்தேர்வுகள்',
        jobfunction_currently: "தற்போதைய பணி",
        //Employee_search.js
        //Job_details.js
        Any: 'ஏதேனும்',
        Not_Applicable: 'பொருந்தாது',
        Preference: 'என் பணியாளர் விருப்பத்தேர்வுகள்',
        Marital_Status: 'திருமண நிலை',
        Age_Criteria: 'வயது அளவுகோல்',
        Languages: 'மொழிகள்',
        No_of_Openings: 'காலியிடங்கள் எண்ணிக்கை',
        Work_Timing: 'வேலை நேரம்',
        shift_name: 'பகுதிநேர வேலை பெயர்',
        start_time: 'தொடக்க நேரம்',
        end_time: 'இறுதி நேரம்',
        Hours: 'மணி',
        Minutes: 'நிமிடங்கள்',
        worktiming: 'தயவு செய்து வேலை நேரத்தைச் சேர்க்கவும்',
        shiftname_exits: 'பகுதிநேர வேலை பெயர் ஏற்கனவே உள்ளது',
        starttime_exits: 'நேரம் ஏற்கனவே உள்ளது',
        shift_error: 'பகுதிநேரவேலை பெயரை தேர்ந்தெடுக்கவும்',
        fromtimetext_error: 'தயவுசெய்து நேரத்தைத் தேர்ந்தெடுக்கவும்',
        totimetext_error: 'இறுதி நேரத்தைத் தேர்ந்தெடுக்கவும்',
        fromtimetext_error1: 'தொடக்க நேரத்தைத் தேர்ந்தெடுக்கவும்',
        totimetext_error1: 'இறுதி நேரத்தைத் தேர்ந்தெடுக்கவும்',
        starthours_error: 'தொடக்க மணி நேரத்தைத் தேர்ந்தெடுக்கவும்',
        startminutes_error: 'தொடக்க நிமிடங்களை தேர்ந்தெடுக்கவும்',
        endhours_error: 'இறுதி மணி நேரத்தைத் தேர்ந்தெடுக்கவும்',
        endminutes_error: 'இறுதி நிமிடங்களை தேர்ந்தெடுக்கவும்',
        fromtimetext_error: 'தொடக்க நேரத்தைத் தேர்ந்தெடுக்கவும்',
        totimetext_error: 'இறுதி நேரத்தைத் தேர்ந்தெடுக்கவும்',
        sametime_error: 'சரியான நேரத்தைத் தேர்ந்தெடுக்கவும்',
        About_Company: 'நிறுவனத்தை பற்றி',
        Activity_Type: 'செயல்பாட்டு வகை',
        Industry_Type: 'தொழில் / துறை',
        Turn_over: 'ஆண்டு விற்பனை',
        No_of_Employees: 'வேலையாட்களின் எண்ணிக்கை',
        Facilities_Offered: 'வழங்கப்படும் வசதிகள்',
        Interview_Location: 'நேர்காணல் இடம்',
        //Jobpost_view
        Loading: 'ஏற்றுகிறது...',
        My_Jobs: 'எனது வேலைகள்',
        New_Job_Post: 'புதிய வேலை இடுகை',
        My_Job_Posts: 'எனது வேலை இடுகைகள்',
        Posted_On: 'இடுகையிடப்பட்ட நாள்',
        approved_on: 'அங்கீகரிக்கப்பட்ட நாள்',
        Job_Details: 'வேலை விவரங்கள்',
        Search_Profiles: 'சுய விவரங்களைத் தேடுங்கள்',
        Matching_Profiles: 'பொருந்தும் சுயவிவரங்கள்',
        Applied: 'விண்ணப்பிக்கப்பட்டது',
        //Shortlisted: 'தேர்ந்தெடுக்கப்பட்டது',
        Wishlist: 'விருப்பப்பட்டியல்',
        //Myjobpost.js
        no_job_post: 'சந்தாக்கள் எதுவும் இல்லை. clickhere to add',
        new_Job_Post: 'புதிய வேலை இடுகை',
        Days_Left: 'மீதமுள்ள நாட்கள்',
        Expiring_Today: 'இன்று காலாவதியாகிறது',
        Expiring_Soon: "விரைவில் காலாவதியாகிறது",
        Pending: 'நிலுவையில் உள்ளது',
        Approved: 'அங்கீகரிக்கப்பட்டது',
        Rejected: 'நிராகரிக்கப்பட்டது',
        On_Off: 'ஆன் / ஆஃப்',
        //Newjobpost.js
        Language: 'மொழி',
        Branch: 'கிளை',
        Add_Work_timing: 'வேலை நேரத்தைச் சேர்க்கவும்',
        Shift_name: 'பகுதிநேர வேலை பெயர்',
        Educationcategory: 'கல்வி வகை',
        Specialization: 'நிபுணத்துவம்',
        From: 'முதல் ',
        To: 'வரை',
        No_of_Openings: 'காலியிடங்களின் எண்ணிக்கை',
        Last_Date: 'கடைசி தேதி',
        Description: 'விளக்கம்',
        Job_Group: "வேலை குழு",
        Specific: "குறிப்பிட்ட",
        Preferred_Candidate_Location: 'வேலைநாடுநரின் விருப்ப இருப்பிடம்',
        Preferred_Job_Location: 'வேலை தேடுவோர்  பணி விருப்ப இடம் மாவட்டம்',
        Preferred_Job_Taluk: 'வேலை தேடுவோர்  பணி விருப்ப தாலுகா',
        Cand_home_location: 'வேலை தேடுவோர் இல்லத்தின் இருப்பிடம் மாவட்டம்',
        Cand_home_taluk: 'வேலை தேடுவோர் இல்லத்தின் தாலுகா',
        State: 'மாநிலம்',
        District: 'மாவட்டம்',
        Taluk: 'தாலுகா',
        City: "டவுன் / நகரம்",
        HR_Contact_Details: 'மனிதவள தொடர்பு விவரங்கள்',
        Contact_Person_Name: 'தொடர்பு கொள்ள வேண்டிய நபரின் பெயர்',
        Designation: 'பதவி',
        Email_ID: 'மின்னஞ்சல் முகவரி',
        Mobile_No: 'கைபேசி எண் (வாட்ஸ்அப் உடன்)',
        Alternative_Contact_No: 'மாற்று தொடர்பு எண்',
        Address: 'முகவரி',
        Map_Location: 'வரைபடத்தில் இருப்பிடம்',
        Latitude: 'அட்சரேகை',
        Longitude: 'தீர்க்கரேகை',
        Search_Places: 'இடங்களை தேடுக...',
        //Events.js
        Events: 'நிகழ்வுகள்',
        Link: 'இணைப்பு',
        Venue: 'இடம்',
        Date: 'தேதி',
        Expirydate: 'காலாவதி தேதி',
        //NewsEventsSidebar.js
        News: 'செய்தி',
        website: 'www.vinmeeninfotech.com',
        Contact_Us: 'எங்களை தொடர்பு கொள்ள',
        Login_With_Auth0: 'Login With Auth0',
        Email: 'மின்னஞ்சல் முகவரி',
        Password: 'பாஸ்வேர்ட்',
        Forgot_Password: 'பாஸ்வேர்ட் மறந்துவிட்டீர்களா?',
        Register: 'பதிவு செய்க',
        Login: 'உள்நுழைக',
        rememberme: 'என்னை நினைவில் கொள்க',
        OR: 'அல்லது',
        email_pending: "மின்னஞ்சல் சரிபார்ப்பு நிலுவையில் உள்ளது. உங்கள் மின்னஞ்சல் பெட்டியை சரிபார்க்கவும்",
        acc_blocked: "உங்கள் கணக்கு தடை செய்யப்பட்டுள்ளது. ஜபான்யா நிர்வாகியை தொடர்பு கொள்ளவும்",
        waiting_for_approval: "உங்கள் பதிவு ஒப்புதலுக்காக காத்திருக்கிறது. சந்தேகம் இருப்பின் ஜபான்யா தொடர்பு கொள்ளவும்",
        new_employer: 'புதிய பணியாளர் ?',
        Sign_Up: 'பதிவு செய்ய',
        English: 'English',
        tamil: 'தமிழ்',
        Your_account_is_deactivated: 'உங்கள் கணக்கு செயலிழக்கப்பட்டது',
        Activate_acc: 'உங்கள் கணக்கை செயல்படுத்த விரும்புகிறீர்களா?',
        //Register.js
        Create_Account: 'உங்கள் கணக்கை துவங்குங்கள்',
        create_new_acc: 'புதிய கணக்கை துவங்க பின்வரும் படிவத்தை நிரப்பவும்',
        Firebase: 'Firebase',
        Auth0: 'Auth0',
        JWT: 'JWT',
        //RegisterAuth0.js
        Register_With_Auth0: 'Register With Auth0',
        Name: 'பெயர்',
        Confirm_Password: 'பாஸ்வேர்டை உறுதிப்படுத்தவும்',
        //Activate_email.js
        reg_email: 'உங்கள் பதிவு செய்யப்பட்ட மின்னஞ்சல் முகவரியை உள்ளிடவும்',
        Back_to_Login: 'உள்நுழைவுக்கு திரும்புக',
        Get_OTP: 'ஒடிபி பெறுக',
        Verify_OTP: 'ஒடிபி மூலம் மின்னஞ்சலைச் சரிபார்க்கவும்',
        //Forgotpassword.js
        Recover_your_password: 'உங்கள் பாஸ்வேர்டை மீட்டெடுக்கவும்',
        forgot_msg: "  உங்கள் மின்னஞ்சல் முகவரியை உள்ளிடவும், உங்கள் பாஸ்வேர்டை எவ்வாறு மீட்டமைப்பது என்பதற்கான வழிமுறைகளை உங்களுக்கு அனுப்புவோம்.",
        Recover_Password: 'பாஸ்வேர்டை மீட்டெடுக்கவும்',
        //Get_otp.js
        enter_otp: 'உங்கள் ஒடிபிஐ உள்ளிடவும்',
        OTP: 'ஒடிபி',
        Resend_OTP: 'ஒடிபிஐ மீண்டும் அனுப்பவும் ',
        Submit: 'சமர்ப்பிக்கவும்',
        Verified: 'சரிபார்க்கப்பட்டது',
        //Lockscreen.js
        session_lock: 'Your Session is locked',
        Username: 'பயனர்பெயர்',
        Unlock: 'Unlock',
        not_john: 'Are you not John Doe ?',
        Reset_Password: 'பாஸ்வேர்டை மீட்டமை',
        new_password: 'தொடர, உங்கள் புதிய பாஸ்வேர்டை உள்ளிடவும்',
        continue: 'தொடரவும்.',
        New_Password: 'புதிய பாஸ்வேர்ட்',
        //Resetpassword.js
        paratag: 'தொடர, உங்கள் மின்னஞ்சல் முகவரி மற்றும் புதிய பாஸ்வேர்டை உள்ளிடவும்',
        go_back_login: 'உள்நுழைவுக்குச் செல்லவும்',
        Reset: 'மீட்டமை',
        //Signup.js
        Employer_Registration: 'வேலையளிப்போர் பதிவு செய்தல்',
        Registered_Company_Name: 'பதிவு செய்யப்பட்ட நிறுவனத்தின் பெயர்',
        Company_Type: 'நிறுவனத்தின் வகை',
        city_town: 'டவுன் / நகரத்தின் பெயர்',
        industry_sector: 'தொழில்துறை / பிரிவு',
        Employer_Type: 'வேலையளிப்போர் வகை',
        Registered_Company_Address: 'பதிவு செய்யப்பட்ட நிறுவனத்தின் முகவரி',
        Company_Address: 'நிறுவனத்தின் முகவரி',
        Company_Address_Placeholder: '(கதவு எண், தெருவின் பெயர், இடம்)',
        Website: 'இணையதள முகவரி ',
        Pincode: 'அஞ்சல் குறியீடு',
        reg_email_signup: 'பதிவுசெய்யப்பட்ட மின்னஞ்சல்',
        Mobile_Number: 'கைபேசி எண் (வாட்ஸ்அப் உடன்)',
        Telephone: 'தொலைபேசி எண்',
        Contact_Person: 'தொடர்பு கொள்ள வேண்டிய நபர் ',
        know_bestjobs: 'ஜபான்யா பற்றி உங்களுக்கு எப்படி தெரியும் ?',
        GSTIN1: 'ஜிஎஸ்டி எண்',
        GSTIN: 'ஜிஎஸ்டி எண்(எல்லா பக்கங்களையும் சேர்க்கவும்)',
        PAN: 'பான் எண்',
        PAN_no: 'பான் எண்',
        Aadhar_No: 'உதயம்/உத்யோக் ஆதார் சான்றிதழ்',
        Aadhar_no: 'ஆதார் எண்',
        i_agree: 'ஜபான்யா - விதிமுறைகள் மற்றும் நிபந்தனைகளை நான் ஒப்புக்கொள்கிறேன்',
        terms_and_conditions: 'விதிமுறைகள் மற்றும் நிபந்தனைகளை',
        Prev: 'முந்தைய',
        reg_success: 'உங்கள் பதிவுக்கு நன்றி!',
        thank_contactus: 'எங்களை தொடர்பு கொண்டதற்கு நன்றி',
        reg_success_one: 'மின்னஞ்சல் சரிபார்ப்புக்கு உங்கள் இன்பாக்ஸை சரிபார்க்கவும். உங்கள் மின்னஞ்சல் சரிபார்க்கப்பட்டதும், நீங்கள் வேலையளிப்போர் போர்ட்டலில் உள்நுழைய தயாராக உள்ளீர்கள்.',
        Click_here_to_Login: 'உள்நுழைய இங்கே கிளிக் செய்க',
        View: 'காண்க',
        View_Contact: 'தொடர்பை காண்க',
        reg_success1: 'உங்கள் பதிவு வெற்றிகரமாக சமர்ப்பிக்கப்பட்டுள்ளது.',
        reg_success2: 'பதிவுசெய்த மின்னஞ்சல்க்கு அனுப்பப்பட்ட சரிபார்ப்பு இணைப்பை கிளிக் செய்வதன் மூலம் உங்கள் மின்னஞ்சல் சரிபார்க்கவும்.',
        Ok: 'சரி',
        //change_password.js
        Change_Password: 'பாஸ்வேர்டை மாற்று',
        Old_Password: 'பழைய பாஸ்வேர்ட்',
        psw_success: 'உங்கள் பாஸ்வேர்ட் வெற்றிகரமாக மாற்றப்பட்டுள்ளது. பயன்பாட்டிலிருந்து வெளியேற வேண்டிய கட்டாயத்தில் உள்ளீர்கள். மீண்டும் உள்நுழைக',
        //Employer_profile.js
        Edit: 'தொகுத்து அமை',
        Registered_on: 'பதிவு செய்யப்பட்ட நாள்',
        Approved_on: 'அங்கீகரிக்கப்பட்ட நாள்',
        Referals: 'பரிந்துரைத்தவர்',
        Registered_office: 'பதிவுசெய்யப்பட்ட அலுவலகம்',
        Government_Identification: 'அரசு அடையாளம்',
        Annual_Turn_Over: 'ஆண்டு விற்பனை',
        Total_Employees: 'மொத்த ஊழியர்கள்',
        Facility_Offered: 'வழங்கப்படும் வசதிகள்',
        Photo_Gallery: 'புகைப்பட தொகுப்பு',
        No_Record_found: 'எந்த பதிவுகளும் கண்டறியப்படவில்லை',
        Branches: 'தலைமை அலுவலகம் & கிளை விவரங்கள்',
        Branches1: 'உங்கள் தலைமை அலுவலகத்தை கிளையாக சேர்க்கவும்',
        Approval: 'ஒப்புதல்',
        Remarks: 'குறிப்புகள்',
        Approve: 'அங்கீகரி',
        Block: 'தடைசெய்',
        Reject: 'நிராகரி',
        UnBlock: 'தடைநீக்கு',
        Close: 'மூடு',
        Phone_No: 'தொலைபேசி எண் (வாட்ஸ்அப் உடன்)',
        Update: 'புதுப்பிக்க',
        gallery: "குறிப்பு: அதிகபட்சம் 3 படங்கள் அனுமதிக்கப்படும்",
        Branch_Name: 'கிளை பெயர்',
        Branch_Type: 'கிளை வகை',
        Delete: 'அழி',
        update_preference_details: 'விருப்பத்தேர்வு விவரங்களைப் புதுப்பிக்கவும்',
        update_company_details: 'நிறுவனத்தின் விவரங்களைப் புதுப்பிக்கவும்',
        Update_Branch_Details: 'கிளை விவரங்களை புதுப்பிக்கவும்',
        About_company: 'நிறுவனத்தை பற்றி',
        Turn_Over: 'ஆண்டு விற்பனை',
        no_of_employee: 'வேலை ஆட்கள் எண்ணிக்கை',
        Update_Profile_Details: 'கிளை விவரங்களை புதுப்பிக்கவும்',
        Registered_name: 'பதிவு செய்யப்பட்ட பெயர்',
        Industry: 'தொழில்துறை',
        Employertype: 'வேலையளிப்போர் வகை',
        Companytype: 'நிறுவனத்தின் வகை',
        Details: 'விவரங்கள்',
        My_Profile: 'என் சுயவிவரம்',
        Settings: 'பகிர்',
        //Settings.js
        Change_Email_ID: 'மின்னஞ்சல் முகவரி மாற்றவும்',
        Current_Email_ID: 'தற்போதைய மின்னஞ்சல் முகவரி',
        New_Email_ID: 'புதிய மின்னஞ்சல்',
        Notification: 'அறிவிப்பு',
        Invisible_Mode: 'கண்ணுக்கு தெரியாத பயன்முறை',
        Invisible_Mode_one: 'வேலை நாடுநர் காணக்கூடிய கணக்கு / காணவியலாத கணக்கு',
        Invisible_Mode_1: 'உங்கள் கணக்கை கண்ணுக்கு தெரியாத பயன்முறையில் மாற்ற விரும்புகிறீர்களா ?',
        Cancel: 'ரத்து செய்க',
        No_Notifications_yet: 'இதுவரை அறிவிப்புகள் எதுவும் இல்லை',
        Dismiss_all: 'அனைத்தையும் நிராகரி7',
        Job: 'வேலை',
        Notifications: 'அறிவிப்புகள்',
        //Search_profile.js
        isany: 'ஏதேனும்',
        //subscription
        no_active: 'சந்தாக்கள் எதுவும் இல்லை. clickhere to',
        subscribe: 'சந்தா செலுத்த',
        no_of_jobpost: 'வேலை இடுகைகளின் எண்ணிக்கை',
        no_of_Vacancies: 'காலியிடங்களின் எண்ணிக்கை / வேலை இடுகை',
        no_of_view: 'காணமுடிந்த சுயவிவரங்களின் எண்ணிக்கை / வேலை இடுகை',
        no_of_applied: 'விண்ணப்பிக்கப்பட்ட சுயவிவரங்களின் எண்ணிக்கை / வேலை இடுகை',
        no_of_invited: 'அழைக்கப்பட்ட சுயவிவரங்களின் எண்ணிக்கை / வேலை இடுகை',
        no_of_shortlisted: 'தேர்ந்தெடுக்கப்பட்ட சுயவிவரங்களின் எண்ணிக்கை / வேலை இடுகை',
        Total: 'மொத்தம்',
        Posted: 'இடுகையிடப்பட்டது',
        Posted_one: 'இடுகையிடப்\nபட்டது',
        Available: 'கிடைக்கிறது',
        Active: 'செயலில் உள்ளது',
        Days: 'நாட்களில்',
        max_validity: 'ஒரு வேலை பதவிக்கு அதிகபட்ச செல்லுபடியாகும், இடுகையிடப்பட்ட நாளிலிருந்து',
        Expires_on: 'காலாவதியாகும் நாள் ',
        Select: 'தேர்ந்தெடுக',
        No_of_Profiles: 'சுயவிவரங்களின் எண்ணிக்கை',
        No_of_Vacancies: 'காலியிடங்களின் எண்ணிக்கை / வேலை இடுகை',
        No_of_Posts: 'வேலை இடுகை எண்ணிக்கை',
        plan_validity: "திட்டம் செல்லுபடியாகும் நாட்களின் எண்ணிக்கை",
        Subscribed_on: 'சந்தாசெலுத்திய நாள்',
        Unlimited: 'வரம்பு இல்லை',
        Expired_on: 'காலாவதியான நாள்',
        Plan_Validity_on: 'திட்ட செல்லுபடியாகும்',
        Custom_Plan: 'தனிப்பயன் திட்டம்',
        more_plan: 'நீங்கள் அதிக எண்ணிக்கையிலான வேலை இடுகைகள் மற்றும் சுயவிவரங்களைத் தேடுகிறீர்களா ?',
        My_Subscriptions: 'எனது சந்தாக்கள்',
        History: 'வரலாறு',
        Subscribe: 'சந்தா செலுத்த',
        Subscribed_Successfully: 'வெற்றிகரமாக சந்தா பெறப்பட்டுள்ளது ',
        location_exceed: 'இருப்பிடம் வரம்பை மீறுகிறது',
        branchname_valid: 'செல்லுபடியாகும் கிளை பெயரை உள்ளிடவும்',
        valid_name: 'சரியான பெயரை உள்ளிடவும்',
        valid_turnover: 'சரியான வருவாயை உள்ளிடவும்',
        valid_desigination: 'செல்லுபடியாகும் பதவியை உள்ளிடவும்',
        Similar_search:'பொருந்தும் சுயவிவரங்களைக் காண்க',
        Exact_search: 'துல்லியமாக பொருந்தும் சுயவிவரங்களைக் காண்க',
        Use_filter: 'சுயவிவரங்களை பட்டியலிட  இடதுபுறத்தில் உள்ள வடிகட்டியை பயன்படுத்தவும்',
        Not_very_specific: '(மிகவும் துல்லியமானதாக இல்லை)',
        valid_noof: 'செல்லுபடியாகும் எண் உள்ளிடவும். ஊழியர்',
        valid_address: 'செல்லுபடியாகும் முகவரியை உள்ளிடவும்',
        invite_qus: 'நீங்கள் நிச்சயமாக அழைக்க விரும்புகிறீர்களா?',
        invite_confirmQus:'வெற்றிகரமாக அழைக்கபட்டுள்ளீர்கள். இப்போது நீங்கள் இந்த ஊழியரை அழைக்கலாம் @ ',
        call_confirm:'நீங்கள் இந்தப் பணியாளரை அழைக்கலாம் @',
        applyaccept: 'நீங்கள் நிச்சயமாக ஏற்றுக்கொள்ள விரும்புகிறீர்களா?',
        applydecline: 'நீங்கள் நிச்சயமாக மறுக்க விரும்புகிறீர்களா?',
        employer_preference_word: "உங்கள் முக்கியத்துவம் வாய்ந்த துறைகளில் சிறந்த திறமையுடையவர்களை கண்டறிய விருப்பத்தேர்வுகளை தேர்ந்தெடுக்கும்போது மிகவும் திட்டவட்டமாக இருங்கள்.",
        employee_preference_word: "விருப்பத்தேர்வுகளை தேர்ந்தெடுக்கும்போது மிகவும் திட்டவட்டமாக இருங்கள். இதன் மூலம் நீங்கள் சரியான வேலை அறிவிப்புகள் மற்றும் வேலை பரிந்துரைகளை பெறுவீர்கள்.",
        // keep_document_ready: "KEEP THESE DOCUMENTS READY \n # GST   # PAN   # AADHAR \n in \n PDF Format \n FOR REGISTRATION",
        keep_document_ready: "இந்த ஆவண எண்களைத் தயாராக வைத்திருங்கள்",
        keep_document_ready1: "#கம்பெனி ஜிஎஸ்டி #கம்பெனி பான் #உதயம்/உத்யோக் ஆதார்",
        keep_document_ready2: "",
        keep_document_ready3: "பதிவு செய்வதற்கு",
        keep_document_ready4: "JPG/JPEG/PDF வடிவத்தில் வைத்திருக்கவும்",
        as_per_settings: 'உங்கள் பணியாளர் விருப்பத்தேர்வு அமைப்புகளின்படி',
        from_age: 'ஆரம்ப வயது வரம்பு',
        to_age: 'இறுதி வயது வரம்பு',
        from_sal: 'சம்பள ஆரம்ப தொகை',
        to_sal: 'சம்பள இறுதி தொகை',
        upto: 'அது வரை ',
        any_degree: "ஏதேனும் ஒரு கல்வி வகை",
        any_spec: 'ஏதேனும் ஒரு நிபுணத்துவம்',
        any_qual: 'ஏதேனும் ஒரு தகுதி',
        txt_back: "மீண்டும்",
        txt_edit: "தொகு",
        txt_add: "சேர்",
        plan_name: "திட்ட பெயர்",
        price_in_month: "விலை ரூபாயில் / திட்டம்",
        no_of_post: "பணியிடங்களின் எண்ணிக்கை",
        plan_validity: "நாட்களில் செல்லுபடியாகும் திட்டம்",
        max_validity_post: "வேலை இடுகை பதிவிட தேதியில் இருந்து செல்லுபடியாகும் நாட்களின் எண்ணிக்கை",
        comparsion_table: "ஒப்பீட்டு அட்டவணை",
        plan_name: "திட்டத்தின் பெயர்",
        gst_applicable: "( ஜிஎஸ்டி பொருந்தும் )",
        gst_applicable1: "* ஜிஎஸ்டி பொருந்தும்",
        subscription_failed: "சந்தா தோல்வியுற்றது",
        subscriptions_buy_notes: "உங்கள் விருப்ப வேலை நாடுநர் பட்டியலில் போதுமான அளவு தகுதியுள்ள போட்டியாளர்களின் விவரங்களை பார்த்த பிறகு சந்தா செலுத்துங்கள்",
        exp_year_str: "ஆண்டுகள்",
        exp_year_str1: "ஆண்டு",
        exp_month_str: "மாதங்கள்",
        exp_month_str1: "மாதம்",
        jobpost_approval_info: 'தயவுசெய்து கவனிக்கவும்: உங்கள் வேலை இடுகை அங்கீகரிக்கப்பட்டு இடுகையிடப்பட்டபின் மாற்றி அமைக்க முடியாது',
        employer_description: "வேலை இடுகை நிராகரிப்பை தவிர்க்க, வேலை விளக்கத்தில் தொடர்பு தகவலை உள்ளிட வேண்டாம் ",
        total_no_profile: "மொத்த சுயவிவரங்களின் எண்ணிக்கை",
        jobpostlist_hint: "உங்கள் பட்டியலிடப்பட்ட வேலை இடுகையை கிளிக் செய்து, தொடர்புடைய பணியாளர்கள் விவரங்களைப் பார்க்கவும்",
        cart_text: "இப்போது வாங்க",
        click_to: 'கிளிக் செய்யவும்',
        post_job: '+ வேலை இடுகையிடவும்',
        post_job_btn: 'வேலை இடுகையிடவும்',
        save_as_draft: 'வரைவாக சேமி',
        abuse_txt: " புகார் செய்",
        employer_profile_txt: 'உங்கள் சுயவிவரத்தை சுவாரஸ்யமாக்க உங்கள் நிறுவனத்தின் லோகோவை பதிவேற்றவும் !',
        employer_gallery_txt: 'உங்கள் சுயவிவரத்தை சுவாரஸ்யமாக்க உங்கள் நிறுவனத்தின் புகைப்படங்களை  பதிவேற்றவும் !',
        text_viewed: 'பார்க்கப்பட்டது',
        Match_pro: "பொருந்தும் சுயவிவரங்கள்",
        please_choose_district: 'மாவட்டத்தில் ஏதேனும் ஒன்றைத் தேர்வு செய்யவும்',
        Rejected_job: "இந்த வேலை நிராகரிக்கப்பட்டது",
        Additional_document: "கூடுதல் ஆவணங்கள் பதிவேற்றம் செய்யப்பட வேண்டும்",
        Addition_Documents: "கூடுதல் ஆவணங்கள்",
        Document_type: "ஆவண வகை",
        doument_error: "குறைந்தபட்சம் ஒரு ஆதாரத்தை பதிவேற்றவும்",
        Document_total_error: "குறைந்தது மூன்று ஆவணங்களை பதிவேற்றவும்",
        Reference_number: "குறிப்பு எண்",
        Reference_number_error: "குறிப்பு எண்ணை உள்ளிடவும்",
        Document_type_Error: "ஆவண வகையைத் தேர்ந்தெடுக்கவும்",
        no_rec_taluk: 'பதிவு எதுவும் கிடைக்கவில்லை',
        last_login: "கடைசி கணக்கு செயல்பாடு",
        per_month: "ஒரு மாதத்திற்கு",
        employer_editable: "(திருத்தக்கூடியது)",
        employer_noneditable: "(திருத்த முடியாதது)",
        any_state: " Any State",
        any_district: " Any District",
        any_taluk: " Any Taluk",
        mobilenoexists:"தொலைபேசி எண். ஏற்கனவே இருக்கிறது",
        job_preferences:"என் வேலை விருப்பத்தேர்வுகள்",
        campany_photos:"நிறுவனத்தின் புகைப்படங்கள்",
    }
});
export default strings;