module.exports = global.config = {
  geturl: {
    ipaddress: {
      // url: "https://devapi.jobanya.com/api/controller/", // DEV
      // url: "https://apitest.jobanya.com/api/controller/"// Test
      //  url: "https://apilive.jobanya.com/api/controller/"// LIVE

      url: "https://uatapi.jobanya.com/api/controller/"// DEV
      //url: "https://api.jobanya.com/api/controller/"// Live
      //url: "https://api.thebestjobs.in/api/controller/" //Live
      // url: "https://testapi.thebestjobs.in/api/controller/"   //Testing
      // url: "https://apiuat.thebestjobs.in/api/controller/"   //UAT
      // url: "http://172.16.1.250:8800/api/controller/" //250
      // url: "https://employerapi.jobanya.com/api/controller/" // NEW jobanya Live
    },
    // rest of your translation object
  },
  // other global config variables you wish
};
